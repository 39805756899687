import { useNavigate } from "react-router-dom"

import Swal from "sweetalert2"

export default function RouteNotFound() {
  const navigate = useNavigate()

  Swal.fire({
    icon: "warning",
    allowOutsideClick: false,
    confirmButtonColor: "#29333C",
    confirmButtonText: "        ME TIRE DAQUI        ",
    text: "Oops, essa página não foi encontrada.",
  }).then(() => {
    navigate("/auth")
  })

  return null
}
