import { useNavigate } from "react-router-dom"
import { ChangeEvent, useEffect, useState } from "react"
import { Alert, AlertTitle, Grid, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import useAlert from "../../hooks/useAlert"
import Header from "../../components/Header"
import { sortByCV } from "../../helpers/sort"
import { ISections } from "../../@types/Sections"
import { IVacancy } from "../../@types/Vacancies"
import MainPaper from "../../components/MainPaper"
import useVacancies from "../../hooks/useVacancies"
import VacancyCard from "../../components/VacancyCard"
import SearchButton from "../../components/SearchButton"
import SortVacancies from "../../components/SortVacancies"
import SectionsButton from "../../components/SectionsButton"
import MobileContainer from "../../components/MobileContainer"
import BackdropLoading from "../../components/BackdropLoading"
import { requestVacanciesBySections } from "../../services/getVacancies"
import NavigationMenu from "../../components/VacanciesMenuButton"

export default function Vacancies() {
  const navigate = useNavigate()
  const { alert, setAlert } = useAlert()
  const { vacancies, setVacancies } = useVacancies()
  const [searchFilter, setSearchFilter] = useState<string>("")
  const [filteredSection, setFilteredSection] = useState<ISections>(
    JSON.parse(localStorage.getItem("selectedSection") as string)
  )
  const [vacancyType, setVacancyType] = useState<IVacancy[]>(vacancies?.novasVagas)
  const [selectedOption, setSelectedOption] = useState<string>("Número de chamado");

  useEffect(() => {
    if (vacancies?.secaoDescricao === filteredSection?.descricao) return

    setAlert({ ...alert, waitingResponse: true })

    const getVacancies = async () => {
      let response = await requestVacanciesBySections()

      localStorage.setItem("backPath", "/auth/vagas-direcionadas")

      setVacancies(response)
      setVacancyType(sortByCV(response?.novasVagas))
      setSelectedOption("Número de chamado");

      setAlert({ ...alert, waitingResponse: false })
    }

    getVacancies()
  }, [filteredSection])

  const handleVacancyCard = (vacancy: IVacancy) => {
    localStorage.setItem("selectedVacancy", JSON.stringify(vacancy))

    if (vacancy?.status !== "Ativa") {
      return
    } else {
      navigate("/auth/detalhes-vaga")
    }
  }

  const searchVacancy = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value)
  }

  const clearSearchFilter = () => {
    setSearchFilter("")
  }

  let searchFilterResult = !searchFilter
    ? vacancyType
    : vacancyType?.filter(
      vacancy =>
        vacancy.nome.toLowerCase().includes(searchFilter.toLocaleLowerCase().trim()) ||
        vacancy.nrChamado.includes(searchFilter.trim())
    )

  const renderNoVacancyFound = () => (
    <Alert severity='info' variant='outlined'>
      <AlertTitle>
        <strong>Vaga não encontrada</strong>
      </AlertTitle>
      Não existe nenhuma vaga para <strong>{searchFilter}</strong> nesta seção.
    </Alert>
  )

  const useStyles = makeStyles((theme: Theme) => ({
    paperContainer: {
      padding: 16,
      /* overflow: "auto", */
      justifyContent: "center",
      height: "100zh",
      backgroundColor: "#E8E8E8",
    },
    cardGrid: {
      marginBottom: 12,
      position: "relative",
    },
    sortingGrid: {
      height: 24,
      padding: 2,
      width: "100%",
      borderRadius: 4,
      marginBottom: 16,
      border: "2px solid",
      borderColor: theme.palette.primary.light,
    },
    sortingText: {
      marginLeft: 4,
      color: "#000",
      marginBottom: 16,
    },
  }))

  const classes = useStyles()

  return (
    <MobileContainer blueBackground style={{ padding: 0, backgroundColor: "#FFF" }}>
      <Header />
      <MainPaper
        height='100%'
        overflow
        position="static"
        transform="none"
      >

        <Grid style={{ marginBottom: 24, paddingRight: 16, paddingLeft: 16 }}>
          <NavigationMenu activePage="novas-vagas" />
        </Grid>

        <Grid style={{ marginBottom: 24, paddingRight: 16, paddingLeft: 16 }}>
          <SearchButton
            value={searchFilter}
            onChange={searchVacancy}
            clearInput={clearSearchFilter}
          />
        </Grid>

        <Grid style={{ marginBottom: 16, paddingRight: 16, paddingLeft: 16 }}>
          <h2 style={{ color: "#1D3054", marginBottom: 8, fontSize: 16 }}>Seção/Departamento</h2>
          <SectionsButton filteredSection={filteredSection} setFilteredSection={setFilteredSection} />
        </Grid>

        <Grid style={{ marginBottom: 12, paddingRight: 16, paddingLeft: 16 }}>
          <SortVacancies vacancyType={vacancyType} setVacancyType={setVacancyType} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
        </Grid>

        <Grid style={{
          backgroundColor: "#E8E8E8",
        }}>
          <h2 style={{ color: "#1D3054", marginBottom: 8, paddingTop: 16, fontSize: 20, paddingRight: 16, paddingLeft: 16 }}>Novas vagas</h2>
          <h4 style={{ color: "#4D4D4D", fontSize: 14, paddingBottom: 8, paddingRight: 16, paddingLeft: 16 }}>
            Veja abaixo as vagas que você pode assumir
          </h4>
        </Grid>
        <Grid container className={classes.paperContainer}>
          {searchFilterResult?.length === 0 && vacancyType?.length !== 0 ? (
            renderNoVacancyFound()
          ) : vacancyType?.length === 0 ? (
            <Alert severity='info' variant='outlined'>
              <AlertTitle>
                <strong>Vaga não encontrada</strong>
              </AlertTitle>
              Não existe nenhuma vaga nesta seção.
            </Alert>
          ) : (
            <>
              {searchFilterResult?.map(vacancy => (
                <Grid container key={vacancy.id} className={classes.cardGrid}>
                  <VacancyCard
                    job={vacancy.nome}
                    nomeCandidato={vacancy.nomeCandidato}
                    cv={vacancy.nrChamado}
                    status={vacancy.status}
                    horario={vacancy.horario}
                    salario={vacancy.salario}
                    linkVaga={vacancy.linkVaga}
                    onClick={() => handleVacancyCard(vacancy)}
                  />
                </Grid>
              ))}
            </>
          )}
          <BackdropLoading open={alert?.waitingResponse} />
        </Grid>
      </MainPaper>
    </MobileContainer>
  )
}
