import { makeStyles } from "@mui/styles"


const Pagination = ({
  length,
  position,
  color = "#003da5",
  onSelected,
  marginBottom = 40
}: { length: number, position: number, color?: string, onSelected?: (n: number) => void, marginBottom?: number }) => {

  const useStyles = makeStyles(() =>
  ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: marginBottom
    },

    spacer: { width: 8 },

    selected: {
      width: 8,
      height: 8,
      backgroundColor: color,
      borderRadius: 4,
      marginRight: 8
    },

    unselected: {
      width: 8,
      height: 8,
      backgroundColor: '#b1b3b3',
      borderRadius: 4,
      marginRight: 8,
      '&:hover': onSelected !== undefined && {
        cursor: 'pointer'
      },
    }
  }))

  const classes = useStyles()

  const elements = [];
  for (let i = 0; i < length; i++) {
    elements.push(
      <div
        className={position === i ? classes.selected : classes.unselected}
        key={String(i)}
        onClick={() => {
          if (onSelected) onSelected(i);
        }}
        tabIndex={0}
        role="button"
        onKeyDown={(e) => {
          if (e.key === 'Enter' && onSelected) {
            onSelected(i);
          }
        }}
      />
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.spacer} />
      {elements}
    </div>
  )

}

export default Pagination
