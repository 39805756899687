import { useState } from "react"

import { Paper, Typography, Grid, Theme } from "@mui/material"

import { makeStyles } from "@mui/styles"

import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa"

import { ISections } from "../@types/Sections"
import VacanciesButton from "./VacanciesButton"

interface SectionsButtonProps {
  filteredSection: ISections
  setFilteredSection: (filteredSection: ISections) => void
}

export default function SectionsButton({
  filteredSection,
  setFilteredSection,
}: SectionsButtonProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    paperDropdown: {
      zIndex: 99,
      left: "50%",
      width: "90%",
      position: "absolute",
      transform: "translateX(-50%)",
    },
    dropdownVacancyNumber: {
      marginRight: 24,
      fontWeight: 700,
      textAlign: "right",
      color: theme.palette.secondary.main,
    },
  }))

  const classes = useStyles()

  const [dropdown, setDropdown] = useState<boolean>(false)

  let sectionCenters = JSON.parse(localStorage.getItem("sectionCenters") as string)

  let selectedSection = JSON.parse(localStorage.getItem("selectedSection") as string)

  const handleDropdown = () => {
    setDropdown(!dropdown)
  }

  const dropdownLabel = (sectionCenter: ISections) => {
    return sectionCenter.descricao
  }

  const sectionCenterFilter = (sectionCenter: ISections) => {
    setFilteredSection(sectionCenter)

    // garantindo que não será feita outra chamada à API
    // caso o usuário selecione o mesmo centro de custo
    if (sectionCenter.descricao === selectedSection?.descricao) {
      setDropdown(false)
      return
    } else {
      localStorage.setItem("selectedSection", JSON.stringify(sectionCenter))

      setDropdown(false)
    }
  }

  const sumNumberOfVacancies = (sectionCenter: ISections) => {
    return sectionCenter.quantidade
  }

  const hideSectionCenters = (sectionCenter: ISections) => {
    if (!window.location.pathname.includes("vagas-em-andamento")) {
      return true
    } else if (
      window.location.pathname.includes("vagas-em-andamento") &&
      sectionCenter.vagasEmAndamento
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <VacanciesButton
        filled
        onClick={handleDropdown}
        buttonText={
          filteredSection?.descricao
            ? dropdownLabel(filteredSection)
            : "Selecione uma seção / departamento"
        }
        lastIcon={
          dropdown ? (
            <FaChevronCircleUp size={24} color='#1D3054' />
          ) : (
            <FaChevronCircleDown size={24} color='#1D3054' />
          )
        }
        disabled={sectionCenters?.length === 0}
      />
      {dropdown ? (
        <Paper className={classes.paperDropdown} elevation={20}>
          {sectionCenters?.map((sectionCenter: ISections) => (
            <Grid
              container
              alignItems='center'
              style={{ marginTop: 4 }}
              key={sectionCenter.descricao}
              justifyContent='space-between'
              onClick={() => sectionCenterFilter(sectionCenter)}
            >
              {hideSectionCenters(sectionCenter) ? (
                <>
                  <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                    <Typography variant='body2' style={{ marginLeft: 24, fontWeight: 700 }}>
                      {dropdownLabel(sectionCenter)}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <hr style={{ height: 16 }} />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Typography variant='body2' className={classes.dropdownVacancyNumber}>
                      {sumNumberOfVacancies(sectionCenter)} vagas
                    </Typography>
                  </Grid>
                </>
              ) : null}
            </Grid>
          ))}
        </Paper>
      ) : null}
    </>
  )
}
