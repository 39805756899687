import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface CustomTooltipProps {
  title: string;
  className?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, className }) => (
  <Tooltip
    title={
      <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
        {title}
      </Typography>
    }
    arrow
    className={className}
  >
    <InfoOutlinedIcon className='tooltipOutlined' />
  </Tooltip>
);

export default CustomTooltip;
