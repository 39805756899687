import ReactDOM from "react-dom/client"

import "./index.css"
import "swiper/swiper-bundle.css"

import App from "./App"
import { BrowserRouter } from "react-router-dom"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

import { datadogRum } from '@datadog/browser-rum';

const environments = {
  PRODUCTION: "production",
  QA: "qa",
  DEVELOPMENT: "development",
  STAGE: "stage",
};

const backendEnvironment = () => {
  const url = window.location.hostname.split(".")[0];

  switch (url) {
    case "administrativo-qa":
      return environments.QA;
    case "administrativo-stage":
      return environments.STAGE;
    case "administrativo":
      return environments.PRODUCTION;
    default:
      return environments.DEVELOPMENT;
  }
};

datadogRum.init({
  applicationId: 'd3a73910-b805-4153-8aaa-ed11c0375864',
  clientToken: 'pub111bfc814140e02257e0e21e112bdbd4',
  site: 'datadoghq.com',
  service: 'onboarding-administrativo',
  env: backendEnvironment(),
  // Specify a version number to identify the deployed version of your application in Datadog 
  // version: '1.0.0', 
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    (url) => url.startsWith("https://apidev.direcional.com.br/onboardingAdministrativo/v1/"),
    (url) => url.startsWith("https://apihmg.direcional.com.br/onboardingAdministrativo/v1/"),
    (url) => url.startsWith("https://apistage.direcional.com.br/onboardingAdministrativo/v1/"),
    (url) => url.startsWith("https://api.direcional.com.br/onboardingAdministrativo/v1/")
  ],
});

const root = ReactDOM.createRoot(document.getElementById("root") as Element)

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

serviceWorkerRegistration.register()
