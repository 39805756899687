import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useDocumentLink from "../../../hooks/useDocumentLink";

const CheckEmptyUser = () =>
{
    const { user } = useDocumentLink();
    const navigate = useNavigate();

    useEffect(() =>
    {
        if(user.email === "") navigate("/link/carregar")
    }, [user, navigate])

    return null;
}

export default CheckEmptyUser;