import { useEffect } from 'react';
import { getEmployeeData } from '../../../services/api';

const useFetchEmployeeData = (vacancyDetails: any, fichaContext: any) => {
  useEffect(() => {
    const fetchEmployeeData = async () => {
      if (vacancyDetails?.empregado?.cpf) {
        try {
          const data = await getEmployeeData(vacancyDetails.empregado.cpf);
          if (fichaContext?.updateFicha) {
            await fichaContext.updateFicha(data);
          }
        } catch (error) {
          console.error('Erro ao buscar dados do empregado:', error);
        }
      }
    };
    fetchEmployeeData();
  }, [vacancyDetails]);
};

export default useFetchEmployeeData;
