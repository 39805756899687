import { ReactNode } from "react"

import { Container, Theme, useMediaQuery } from "@mui/material"

import { makeStyles } from "@mui/styles"

interface LinkMobileContainer {
  children: ReactNode
}

export default function LinkMobileContainer({
  children,
}: LinkMobileContainer) {

const smallMobile = useMediaQuery("(max-height:719px)")
    
  // @ts-ignore
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: 'white',
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: smallMobile ? 0: 8,
      marginTop: smallMobile ? 0: 48,
      boxShadow: "0px 2px 4px -2px rgba(77, 77, 77, 0.06), 0px 4px 8px -2px rgba(77, 77, 77, 0.10)" 
    },
  }))

  const classes = useStyles()
  return (
    <Container maxWidth='xs' className={classes.container}>
      {children}
    </Container>
  )
}
