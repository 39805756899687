import React, { useState, useContext, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import { FormControl, InputLabel, Select, MenuItem, Button, Typography, Grid } from '@mui/material';
import { Ficha } from '../typesFicha';

interface Props {
  onSave: (componentName: string, data: Partial<Ficha>) => void;
  onExit: (componentName: string, data: Partial<Ficha>) => void;
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

const GrauInstrucao: React.FC<Props> = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);
  const [formData, setFormData] = useState({
    grauInstrucao: fichaContext?.ficha.grauInstrucao ?? 0,
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(Boolean(formData.grauInstrucao));
  }, [formData.grauInstrucao]);

  if (!fichaContext) {
    return null;
  }

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name as string]: value,
    });
  };

  const handleSave = () => {
    onSave('grauInstrucao', formData);
  };

  const handleExit = () => {
    onExit('onExit', formData);
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          Grau de instrução
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>
      <form className='formComponente'>
        <Typography variant="h6" className='titleTypography'>
          Grau de instrução <span className='asterisk'>*</span>
        </Typography>
        <FormControl className='formControl' fullWidth>
          <Select
            id="grauInstrucao"
            name="grauInstrucao"
            value={formData.grauInstrucao}
            onChange={(e) => handleChange(e as React.ChangeEvent<{ name?: string; value: unknown }>)}
            label=""
            className='selectField'
            required
            size='small'
          >
            <MenuItem value={0} disabled>
              Selecione...
            </MenuItem>
            <MenuItem value={1}>Até o 5º ano incompleto do ensino fundamental</MenuItem>
            <MenuItem value={2}>Do 5º ano completo do ensino fundamental</MenuItem>
            <MenuItem value={3}>Do 6º ao 9º ano do ensino fundamental</MenuItem>
            <MenuItem value={4}>Ensino fundamental completo</MenuItem>
            <MenuItem value={5}>Ensino médio incompleto</MenuItem>
            <MenuItem value={6}>Ensino médio completo</MenuItem>
            <MenuItem value={17}>Técnico em curso</MenuItem>
            <MenuItem value={18}>Técnico completo</MenuItem>
            <MenuItem value={7}>Educação superior incompleta</MenuItem>
            <MenuItem value={8}>Educação superior completa</MenuItem>
            <MenuItem value={9}>Pós-graduação incompleta</MenuItem>
            <MenuItem value={10}>Pós-graduação completa</MenuItem>
            <MenuItem value={11}>Mestrado incompleto</MenuItem>
            <MenuItem value={12}>Mestrado completo</MenuItem>
            <MenuItem value={13}>Doutorado incompleto</MenuItem>
            <MenuItem value={14}>Doutorado completo</MenuItem>
            <MenuItem value={15}>Pós-doutorado incompleto</MenuItem>
            <MenuItem value={16}>Pós-doutorado completo</MenuItem>
          </Select>
        </FormControl>
      </form>
      <div className='divButtons'>
        <Button
          className='buttonSaveNext'
          variant="contained"
          onClick={handleSave}
          disabled={!isFormValid || isLoading}>
          Próxima Etapa
        </Button>
        <Button
          className='buttonSaveExit'
          variant="contained"
          onClick={handleExit}
          disabled={!isFormValid || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default GrauInstrucao;
