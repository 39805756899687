import React, { useContext, useState, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import DependenteForm from './DependenteForm';
import DependenteCard from './DependenteCard';
import { Dependente, Ficha } from '../typesFicha';
import { Button, Grid, FormControl, FormControlLabel, Checkbox, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { deleteDependente } from '../../../../services/api';
import { swalAlert } from '../../../../helpers/swalAlert';

interface Props {
  onSave: (componentName: string, data: Partial<Ficha>) => void;
  onExit: (componentName: string, data: Partial<Ficha>) => void;
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

const Dependentes: React.FC<Props> = ({ onSave, onExit, setLoading, isLoading }) => {
  const context = useContext(FichaContext);

  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [formData, setFormData] = useState<Partial<Ficha>>({});
  const [hasDependente, setHasDependente] = useState<boolean>(false);

  useEffect(() => {
    if (ficha.dependenteExiste === 'true') {
      setHasDependente(true);
    } else if (ficha.dependenteExiste === 'false') {
      setHasDependente(false);
    } else {
      setHasDependente(true);
    }
  }, []);

  if (!context) {
    return <div>Contexto não encontrado</div>;
  }

  const { ficha, updateFicha } = context;

  const handleAddLine = () => {
    setEditingIndex(null);
    setIsEditing(true);
  };

  const handleEditLine = (index: number) => {
    setEditingIndex(index);
    setIsEditing(true);
  };

  const handleSaveLine = async (data: Dependente) => {
    let updatedDependente = [...(ficha.dependente || [])];
    if (editingIndex !== null) {
      updatedDependente[editingIndex] = data;
    } else {
      updatedDependente.push(data);
    }

    const updatedFicha = {
      dependente: updatedDependente,
      dependenteExiste: updatedDependente.length > 0 ? 'true' : 'false'
    };

    try {
      await updateFicha(updatedFicha);
      setFormData(updatedFicha);
      setIsEditing(false);
    } catch (error) {
      console.error('Erro ao atualizar ficha:', error);
    }
  };

  const handleDeleteDependente = async (index: number) => {
    const dependente = ficha.dependente[index].cpf;
    const nomeDependente = ficha.dependente[index].nome;

    try {
      if (!dependente) {
        console.error('CPF ausente');
        return;
      }
      const response = await deleteDependente(dependente);

      if (response.status >= 200 && response.status < 300) {
        let updatedDependente = [...(ficha.dependente || [])];
        updatedDependente.splice(index, 1);

        const updatedFicha = {
          ...ficha,
          dependente: updatedDependente,
          dependenteExiste: updatedDependente.length > 0 ? 'true' : 'false'
        };

        await updateFicha(updatedFicha);
        setFormData(updatedFicha);
        setIsEditing(false);
        swalAlert(
          "success",
          `<strong>Dependente excluído com sucesso</strong><br />Os dados de <strong>${nomeDependente}</strong> foram excluídos.`,
          "FECHAR"
        );
      } else {
        console.error('Erro ao deletar dependente:', response);
        swalAlert(
          "error",
          `Não foi possível remover o dependente ${nomeDependente}`,
          "TENTAR NOVAMENTE"
        );
      }
    } catch (error) {
      console.error('Erro ao deletar dependente:', error);
      swalAlert(
        "error",
        `Não foi possível remover o dependente ${nomeDependente}`,
        "TENTAR NOVAMENTE"
      );
    }
  };

  const handleDependenteCheckboxChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const updatedFicha = await updateFicha({ dependente: [], dependenteExiste: 'false' });
      setFormData({ dependente: [] });
      setHasDependente(false);
    } else {
      setHasDependente(true);
    }
  };

  const handleSave = () => {
    onSave('dependentes', formData);
  };

  const handleExit = () => {
    onExit('onExit', formData);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const buttonDisabled = () => {
    return (!ficha.dependente || !ficha.dependente.length) && hasDependente;
  };

  if (isEditing) {
    return (
      <DependenteForm
        title={editingIndex !== null ? 'Editar dependente' : 'Adicionar dependente'}
        initialData={
          editingIndex !== null ? ficha.dependente[editingIndex] : {
            empregadoId: 0,
            nome: '',
            cpf: '',
            dataNascimento: '',
            grauParentesco: '',
            irrf: ''
          }
        }
        onSave={handleSaveLine}
        onCancel={handleCancel}
        onDelete={editingIndex !== null ? () => handleDeleteDependente(editingIndex) : undefined}
      />
    );
  }

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          Dependentes
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>

      <div
        onClick={hasDependente ? handleAddLine : undefined}
        className={`buttonAttach ${!hasDependente ? 'checkboxDisabled' : ''}`}
        tabIndex={hasDependente ? 0 : -1}
        role="button"
        onKeyDown={(e) => {
          if (hasDependente && e.key === 'Enter') { handleAddLine(); }
        }}
        aria-disabled={!hasDependente}
      >
        <AddCircleIcon style={{ fontSize: 50, color: '#1D3054' }} />
        <Grid>
          <h2>Adicionar dependente</h2>
          <p>Clique aqui para adicionar um dependente</p>
        </Grid>
      </div>


      {ficha.dependente.length === 0 && (
        <form className='formComponenteCheckbox'>
          <FormControl className='formControl' component="fieldset">
            <FormControlLabel
              control={<Checkbox checked={!hasDependente} onChange={handleDependenteCheckboxChange} />}
              label="Não tenho dependentes"
            />
          </FormControl>
        </form>
      )}

      {ficha.dependente.length > 0 && (
        <div>
          {ficha.dependente.map((dep, index) => (
            <DependenteCard
              key={index}
              dependente={dep}
              onEdit={() => handleEditLine(index)}
            />
          ))}
        </div>
      )}

      <div className='divButtons'>
        <Button
          className='buttonSaveNext'
          variant="contained"
          onClick={handleSave}
          disabled={buttonDisabled() || isLoading}

        >
          Próxima Etapa
        </Button>
        <Button className='buttonSaveExit' variant="contained" onClick={handleExit}
          disabled={buttonDisabled() || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default Dependentes;
