import { Typography, Grid, Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { sortByCV, sortByDate, sortByJob, sortByResponsible, sortByStatus } from "../helpers/sort";
import { Dispatch, SetStateAction } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  sortText: {
    color: "#000",
    fontWeight: 700,
    marginBottom: 8,
  },
  scrollableList: {
    display: "flex",
    overflowX: "auto",
    flexWrap: "nowrap",
    width: "100%",
    whiteSpace: "nowrap",
    "&::-webkit-scrollbar": {
      height: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c8c8c8",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ffffff",
    },
  },
  button: {
    marginRight: 8,
    marginBottom: 4,
    padding: "8px 24px",
    height: 40,
    color: "#1D3054",
    backgroundColor: "#E8E8E8",
    "&:hover": {
      color: "#1D3054",
      backgroundColor: "#c8c8c8",
    },
    minWidth: "auto",
    borderRadius: "200px",
  },
  selectedButton: {
    backgroundColor: "#1D3054",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#1D3054",
      color: "#FFFFFF",
    },
  },
}));

const chipArray = [
  { id: 1, name: "Número de chamado" },
  { id: 2, name: "Profissão" },
  { id: 3, name: "Data de abertura" },
  { id: 4, name: "Responsável" },
  { id: 5, name: "Status" },
];

interface SortVacanciesProps {
  vacancyType: any;
  setVacancyType: (vacancyType: any) => void;
  selectedOption: string;
  setSelectedOption: Dispatch<SetStateAction<string>>;
}

export default function SortVacancies({
  vacancyType,
  setVacancyType,
  selectedOption,
  setSelectedOption,
}: SortVacanciesProps) {
  const classes = useStyles();

  const handleSort = (optionName: string) => {
    switch (optionName) {
      case "Número de chamado":
        setVacancyType(sortByCV(vacancyType));
        break;
      case "Profissão":
        setVacancyType(sortByJob(vacancyType));
        break;
      case "Data de abertura":
        setVacancyType(sortByDate(vacancyType));
        break;
      case "Responsável":
        setVacancyType(sortByResponsible(vacancyType));
        break;
      case "Status":
        setVacancyType(sortByStatus(vacancyType));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body2" className={classes.sortText}>
            Ordenar por:
          </Typography>
        </Grid>

        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.scrollableList}>
          {chipArray.map((option) => (
            <Button
              key={option.id}
              className={`${classes.button} ${option.name === selectedOption ? classes.selectedButton : ""}`}
              onClick={() => {
                handleSort(option.name);
                setSelectedOption(option.name);
              }}
            >
              {option.name}
            </Button>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
