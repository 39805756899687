import { ChangeEvent } from "react"
import { TextField, InputAdornment } from "@mui/material"
import { SearchOutlined, HighlightOff } from "@mui/icons-material"
import { makeStyles } from "@mui/styles"

interface SearchButtonProps {
  value: string
  clearInput: () => void
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export default function SearchButton({ onChange, value, clearInput }: SearchButtonProps) {
  const useStyles = makeStyles(theme => ({
    searchButton: {
      fontWeight: 700,
      borderRadius: 4,
      background: "#FFF",
    },
  }))

  const classes = useStyles()

  const inputIcon = () => {
    if (value) {
      return (
        <InputAdornment position='start'>
          <HighlightOff onClick={clearInput} style={{ cursor: "pointer" }} />
        </InputAdornment>
      )
    } else {
      return (
        <InputAdornment position='start'>
          <SearchOutlined />
        </InputAdornment>
      )
    }
  }

  return (
    <TextField
      fullWidth
      value={value}
      variant='outlined'
      autoComplete='off'
      onChange={onChange}
      placeholder='Pesquisar...'
      className={classes.searchButton}
      InputProps={{
        startAdornment: inputIcon(),

        style: { fontWeight: 700 },
      }}
    />
  )
}
