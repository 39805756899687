import { FormEvent, useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"

import { TextField, Typography } from "@mui/material"

import LinkMobileContainer from "../../../components/LinkMobileContainer"
import LogoHeader from "../../../components/LogoHeader"
import ConfirmButton from "../../../components/ConfirmButton"
import GoBack from "../../../components/GoBack"
import OutlineButton from "../../../components/OutlineButton"
import CheckEmptyUser from "./CheckEmptyUser"
import useDocumentLink from "../../../hooks/useDocumentLink"
import { generateSensediaToken, vacancyLinkLogin } from "../../../services/api"
import LoadingInterface from "../../../components/LoadingInterface"
import { login } from "../../../services/auth"
import { useNavigate } from "react-router-dom"


function ValidateToken() {

  const { tokenSource, user, session, setSession } = useDocumentLink()
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(false)
  const navigate = useNavigate()
  
  const useStyles = makeStyles(theme => ({

    content: {
    margin: 24,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    },

    title: {
        color: '#4d4d4d',
        fontWeight: 700,
        marginBottom: 8,
        fontSize: "24px",
        lineHeight: "32px"
    },

    text: {
      fontSize: "16px",
      marginBottom: 8,
      lineHeight: "24px",
      color: "#4d4d4d",
    },

    sentTo: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#003da5",
      fontWeight: 600,
      marginBottom: 24,
    },

    spacer: {
      height: 24
    },

    separator: {
      height: 2,
      marginTop: 40,
      marginBottom: 40,
      backgroundColor: '#b1b3b3',
    },

    tokenInput: {
        borderRadius: 4,
        marginBottom: 24,
        background: "#fff",
        '& .MuiInput-underline:after': {
          borderBottomColor: error ? '#D00' : '#003da5',
        },
        '& .MuiInput-underline:hover:before':
        {
            borderBottomColor: error ? '#D00' : '#003da5'
        },
      },

  }))

  const classes = useStyles()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try
    {
      
      setError(false)
      setLoading(true)
      const access_token = await generateSensediaToken(
        sessionStorage.getItem('url-link-key')!,
        token,
        session
      )
      login(access_token)
      navigate('/link/bem-vindo')

    } catch {
      setError(true)
    }
    setLoading(false)
  }
  const [token, setToken] = useState('')
  const [resendCooldown, setResendCooldown] = useState(60)

  useEffect(() =>
  {
    const interval = setInterval(() =>
    {
        setResendCooldown(r => 
        {
          const next = r-1;
          if(next === 0) clearInterval(interval)  
          return next;
        })
    }, 1000)
  }, [setResendCooldown])

  if(loading) return (<LoadingInterface/>);

  return (
    <form onSubmit={handleSubmit}>
      <CheckEmptyUser/>
      <LinkMobileContainer>
        <LogoHeader/>

        <div className={classes.content}>
          
          <Typography variant='h6' className={classes.title}>
            Validar acesso
          </Typography>
          <Typography variant='body2' className={classes.text}>
            Enviamos um código para o {tokenSource === "email" ? "e-mail" : "celular"}:
          </Typography>
          <Typography variant='body2' className={classes.sentTo}>
            {tokenSource === "email" ? user.email : `+55 ${user.telefone.substring(0,2)} ${user.telefone.substring(2)}`}
          </Typography>

          <TextField
            fullWidth
            value={token}
            className={classes.tokenInput}
            variant='standard'
            autoComplete='one-time-code'
            onChange={event => setToken(event.target.value.replace(/\D/g, '').substring(0,6))}
            placeholder="Digite o código de verificação"
            inputProps={{style: { textAlign: 'center' }}} 
            error={error}
            helperText={error ? 'Token inválido' : undefined}
            InputProps={
                {
                  style: {
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 8,
                    paddingBottom: 8,
                    textAlign: 'center',
                    fontSize: token.length === 0 ? 16 : 24,
                    lineHeight: 32,
                    color: "#4d4d4d",
                    letterSpacing: token.length === 0 ? undefined : 10.8
                  },
                }
            }
          />

          <OutlineButton
            disabled={resendCooldown > 0}
            onClick={async () => 
            {
                setResendCooldown(60)
                setError(false)
                const i = setInterval(() =>
                {
                    setResendCooldown(resend => 
                    {
                        const n = resend-1;
                        if(n === 0) clearInterval(i)  
                        return n;
                    })
                }, 1000)

                const session = await vacancyLinkLogin(
                  sessionStorage.getItem('url-link-key')!,
                  tokenSource === "email" ? user.email : user.telefone
                )
                setSession(session)
            }}
          >
            REENVIAR CÓDIGO{resendCooldown > 0 ? ` EM ${resendCooldown}S` : ''}
          </OutlineButton>

          <div className={classes.spacer}/>

          <ConfirmButton  disabled={token.length !== 6}>
            ACESSAR
          </ConfirmButton>

          <div className={classes.separator}/>
          <GoBack/>
        </div>
      </LinkMobileContainer>
    </form>
  )
}

export default ValidateToken
