import { ReactNode } from "react"
import MainButton from "./MainButton"

type ConfirmButtonProps = {
    children: ReactNode | string
    onClick?: () => void,
    disabled?: boolean,
    bordered?: boolean
}

const ConfirmButton = ({children, onClick, disabled = false, bordered = false}: ConfirmButtonProps) => {

    return (
        <MainButton
        size='large'
        disabled={disabled}
        type='submit'
        fullWidth={true}
        style={{ 
          backgroundColor: disabled ? '#d1d1d1' : '#003da5',
          color: disabled ? '#848484' : '#ffffff',
          padding: 16,
          fontWeight: 700,
          fontSize: 16,
          lineHeight: "20px",
          marginTop: 8,
          borderRadius: 8,
          borderColor: disabled ? '#d1d1d1' : '#003da5',
          borderWidth: bordered ? 2 : 0
        }}
        buttonStyle='contained'
        onClick={onClick}
      >
        {children}
      </MainButton>
    )

}

export default ConfirmButton