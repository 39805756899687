import React from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import AuthProvider from './context/AuthContext';
import TabsProvider from './context/TabsContext';
import DocumentLinkProvider from './context/DocumentLinkContext';
import { isAuthenticated } from './services/auth';
import Login from './pages/UserRecognition/Login';
import AlertProvider from './context/AlertContext';
import Welcome from './pages/Authenticated/Welcome';
import RouteNotFound from './components/RouteNotFound';
import Vacancies from './pages/Authenticated/Vacancies';
import VacanciesProvider from './context/VacanciesContext';
import DocumentsList from './pages/Authenticated/DocumentsList';
import RemoveVacancy from './pages/Authenticated/RemoveVacancy';
import VacancyDetails from './pages/Authenticated/VacancyDetails';
import ClearVacancyData from './pages/Authenticated/ClearVacancyData';
import VerificationCode from './pages/UserRecognition/VerificationCode';
import VacanciesInProgress from './pages/Authenticated/VacanciesInProgress';
import AccessData from './pages/DocumentLink/Access/AccessData';
import ValidationStart from './pages/DocumentLink/Access/ValidationStart';
import ValidateToken from './pages/DocumentLink/Access/ValidateToken';
import LoadingLink from './pages/DocumentLink/Access/LoadingLink';
import WelcomeLink from './pages/DocumentLink/WelcomeLink';
import HomeLink from './pages/DocumentLink/HomeLink';
import UploadDocument from './pages/DocumentLink/Document/UploadDocument';
import DocumentAttach from './pages/DocumentLink/Document/DocumentAttach';
import FichaProvider from './context/FichaContext';
import FichaCadastral from './pages/DocumentLink/FichaCadastral/FichaCadastral';
import Preenchimento from './pages/DocumentLink/FichaCadastral/Preenchimento';
import { Ficha } from './pages/DocumentLink/FichaCadastral/typesFicha';

const PrivateRoute = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/" />;
};

export default function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1C2F54',
        light: '#848484',
      },
      secondary: {
        main: '#AB3237',
      },
      error: {
        main: '#D00',
      },
    },
  });

  const privateRoutes = [
    { element: <Welcome />, path: '/auth' },
    { element: <RemoveVacancy />, path: '/auth/vaga/excluir' },
    { element: <VacancyDetails />, path: '/auth/detalhes-vaga' },
    { element: <Vacancies />, path: '/auth/novas-vagas' },
    { element: <ClearVacancyData />, path: '/auth/vaga/limpar' },
    { element: <VacanciesInProgress />, path: '/auth/vagas-em-andamento' },
    { element: <DocumentsList />, path: '/auth/documentos' },
    { element: <DocumentAttach />, path: '/auth/documentos/anexar' },
    { element: <UploadDocument />, path: '/auth/documentos/enviar' },
    { element: <WelcomeLink />, path: '/link/bem-vindo' },
    { element: <DocumentsList />, path: '/link/documentos' },
    { element: <DocumentAttach />, path: '/link/documentos/anexar' },
    { element: <UploadDocument />, path: '/link/documentos/enviar' },
    { element: <FichaCadastral />, path: '/link/ficha-cadastral' },
    {
      element: (
        <Preenchimento
          onSave={(componentName: string, data: Partial<Ficha>) => { }}
          onExit={(componentName: string, data: Partial<Ficha>) => { }}
        />
      ),
      path: '/link/ficha-cadastral/preenchimento',
    },
    { element: <HomeLink />, path: '/link/home' },
  ];



  return (
    <AuthProvider>
      <VacanciesProvider>
        <AlertProvider>
          <TabsProvider>
            <DocumentLinkProvider>
              <FichaProvider>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />

                    <Routes>
                      <Route path="/" element={<Login />} />
                      <Route path="/verificar-codigo" element={<VerificationCode />} />

                      <Route element={<PrivateRoute />}>
                        {privateRoutes.map((route, index) => (
                          <Route key={index} {...route} />
                        ))}
                      </Route>
                      <Route element={<RouteNotFound />} />
                      <Route path="/link/carregar" element={<LoadingLink />} />
                      <Route path="/link/dados-acesso" element={<AccessData />} />
                      <Route path="/link/validar" element={<ValidationStart />} />
                      <Route path="/link/token" element={<ValidateToken />} />
                    </Routes>
                  </ThemeProvider>
                </StyledEngineProvider>
              </FichaProvider>
            </DocumentLinkProvider>
          </TabsProvider>
        </AlertProvider>
      </VacanciesProvider>
    </AuthProvider>
  );
}
