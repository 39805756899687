import { useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"

import { Grid, Theme, Typography } from "@mui/material"

import { makeStyles } from "@mui/styles"

import { MdOutlinePersonPin } from "react-icons/md"
import { FaChevronCircleRight } from "react-icons/fa"
import { IoPeopleCircleOutline } from "react-icons/io5"

import { HomeOutlined, EmailOutlined } from "@mui/icons-material"

import useAuth from "../../hooks/useAuth"
import useAlert from "../../hooks/useAlert"
import Header from "../../components/Header"
import { ISections } from "../../@types/Sections"
import MainPaper from "../../components/MainPaper"
import useVacancies from "../../hooks/useVacancies"
import SectionsButton from "../../components/SectionsButton"
import BackdropLoading from "../../components/BackdropLoading"
import MobileContainer from "../../components/MobileContainer"
import VacanciesButton from "../../components/VacanciesButton"
import { requestVacanciesBySections } from "../../services/getVacancies"
import VacanciesTypeButton from "../../components/VacanciesTypeButton"

export default function Welcome() {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      background: theme.palette.primary.main,
      top: 0,
      right: 0,
      margin: 0,
      padding: 0,
      zIndex: -1,
      height: "-webkit-fill-available",
      width: "-webkit-fill-available",
      position: "absolute",
    },
    paperContainer: {
      padding: 16,
      minHeight: "100%",
      justifyContent: "center",
      backgroundColor: "#E8E8E8",
    },
    divider: {
      height: 2,
      width: "70%",
      opacity: 0.4,
      marginTop: 8,
      background: theme.palette.primary.light,
    },
    documentsOnboarding: {
      color: "#000",
      marginLeft: 24,
      fontWeight: 700,
      marginBottom: 8,
    },
  }))

  const { user } = useAuth()

  const classes = useStyles()

  const navigate = useNavigate()

  const { alert, setAlert } = useAlert()

  const { vacancies, setVacancies } = useVacancies()

  const [filteredSection, setFilteredSection] = useState<ISections>(
    JSON.parse(localStorage.getItem("selectedSection") as string)
  )

  useEffect(() => {
    localStorage.removeItem("idAssinatura38")
    localStorage.removeItem("idAssinatura37")
    localStorage.removeItem("isTermsSigned")

    if (vacancies?.secaoDescricao === filteredSection?.descricao) return

    setAlert({ ...alert, waitingResponse: true })

    const getVacancies = async () => {
      let response = await requestVacanciesBySections()

      setVacancies(response)

      setAlert({ ...alert, waitingResponse: false })
    }

    getVacancies()
  }, [filteredSection])

  const vacanciesNumberLabel = (vacancyLength: number) => {
    if (vacancyLength === 0) {
      return 0
    } else if (vacancyLength > 0) {
      return `+${vacancyLength}`
    } else {
      return ""
    }
  }

  const vacanciesHelperText = (vacancyLength: number, helperText: string) => {
    if (vacancyLength > 0) {
      return `${helperText}`
    } else {
      return "Nenhuma vaga disponível na seção/departamento selecionado."
    }
  }

  return (
    <MobileContainer blueBackground style={{ padding: 0, backgroundColor: "#1C2F54" }}>
      <Header transparent />

      <Grid>
        <Grid style={{ paddingRight: 24, paddingLeft: 24, marginBottom: 24 }}>
          <Typography variant='h6' style={{ marginTop: 8, color: "#fff", fontSize: 20 }}>
            Boas-vindas,
          </Typography>
          <Typography variant='h4' style={{ color: "#BFD7FF", fontWeight: 700, fontSize: 28 }}>
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography variant='h6' style={{ color: "#ffffff", fontSize: 14 }}>
            ao onboarding de documentos da Direcional
          </Typography>
        </Grid>

        <MainPaper
          overflow
          position="static"
          transform="none"
        >
          <Grid container className={classes.paperContainer}>

            <Grid style={{
              backgroundColor: "",
            }}>
              <h2 style={{ color: "#1D3054", marginBottom: 8, paddingTop: 16, fontSize: 20, fontWeight: 500 }}>Selecione uma seção/departamento</h2>
              <h4 style={{ color: "#4D4D4D", fontSize: 14, paddingBottom: 8, fontWeight: 400 }}>
                Para acompanhar as vagas que você pode assumir e as assumidas a seleção deve ser feita:
              </h4>
              <SectionsButton
                filteredSection={filteredSection}
                setFilteredSection={setFilteredSection}
              />
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 16, position: "relative" }}>
              <VacanciesTypeButton
                buttonText='Novas vagas'
                disabled={!vacancies?.novasVagas?.length}
                onClick={() => navigate("/auth/novas-vagas")}
                lastIcon={<FaChevronCircleRight size={24} />}
                firstIcon={<IoPeopleCircleOutline size={24} />}
                numberOfVacancies={vacanciesNumberLabel(vacancies?.novasVagas?.length)}
                helperText={vacanciesHelperText(
                  vacancies?.novasVagas?.length,
                  ''
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 16, marginBottom: 16 }}>
              <VacanciesTypeButton
                buttonText='Vagas em andamento'
                helperText=''
                disabled={!vacancies?.vagasEmAndamento?.length}
                onClick={() => navigate("/auth/vagas-em-andamento")}
                lastIcon={<FaChevronCircleRight size={24} />}
                firstIcon={<IoPeopleCircleOutline size={24} />}
                numberOfVacancies={vacanciesNumberLabel(vacancies?.vagasEmAndamento?.length)}
              />
            </Grid>
          </Grid>
        </MainPaper>

        <BackdropLoading open={alert?.waitingResponse} />
      </Grid>
    </MobileContainer>
  )
}
