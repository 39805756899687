import { useState } from "react"

export default function useModal() {
  const [modal, setModal] = useState({
    userNotFound: false,
    removeDocument: false,
    removeAllDocument: false,
    deleteVacancy: false,
    devicePermission: false,
    openPrivacyPolicy: false,
    openTermsOfServices: false,
  })

  return { modal, setModal }
}
