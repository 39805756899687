import { ReactNode } from "react"

import { Paper } from "@mui/material"

import { makeStyles } from "@mui/styles"

interface MainPaperProps {
  children: ReactNode
  height?: string | number
  overflow?: boolean
  position?: "fixed" | "absolute" | "relative" | "static" | "sticky"
  transform?: string
  padding?: string | number
}

export default function MainPaper({ children, height, overflow, position, transform, padding }: MainPaperProps) {
  const useStyles = makeStyles(theme => ({
    paper: {
      right: 0,
      bottom: 0,
      left: "50%",
      width: "100%",
      maxWidth: 444,
      position: position ? position : "fixed",
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
      transform: transform ? transform : "translateX(-50%)",
      height: height ? height : "75vh",
      overflow: overflow ? "auto" : "hidden",
      padding: padding ? padding : 0,
      "&::-webkit-scrollbar": {
        width: "12px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c8c8c8",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#ffffff",
      },
    },
  }))
  const classes = useStyles()

  return (
    <Paper elevation={20} className={classes.paper}>
      {children}
    </Paper>
  )
}
