import { ChangeEvent, useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"

import { Grid, Typography, TextField, Theme } from "@mui/material"

import { makeStyles } from "@mui/styles"

import { PatternFormat } from "react-number-format"

import { MdOutlinePersonPin } from "react-icons/md"
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa"

import useAlert from "../../hooks/useAlert"
import Header from "../../components/Header"
import { IVacancy } from "../../@types/Vacancies"
import MainPaper from "../../components/MainPaper"
import { swalAlert } from "../../helpers/swalAlert"
import useVacancies from "../../hooks/useVacancies"
import MainButton from "../../components/MainButton"
import VacancyInfo from "../../components/VacancyInfo"
import { validateCpf } from "../../helpers/validation"
import { GetSections, TakeVacancy } from "../../services/api"
import BackdropLoading from "../../components/BackdropLoading"
import MobileContainer from "../../components/MobileContainer"
import VacanciesButton from "../../components/VacanciesButton"
import RequiredDocuments from "../../components/RequiredDocuments"
import { requestVacanciesBySections } from "../../services/getVacancies"
import { removeCpfFormat, checkSpecialCharactersOrNumbers } from "../../helpers/format"
import GoBackHeader from "../../components/GoBackHeader"
import useModal from "../../hooks/useModal"
import DeleteClearButton from "../../components/DeleteClearButton"

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    padding: 12,
  },
  card: {
    padding: 12,
    borderRadius: 8,
    marginBottom: 12,
  },
  job: {
    marginLeft: 8,
    fontWeight: 700,
  },
  vacancySubtitles: {
    color: "#000",
    fontWeight: 700,
  },
  takeVacancy: {
    marginLeft: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  documentsOnboarding: {
    color: "#000",
    marginLeft: 24,
    marginBottom: 4,
    fontWeight: 700,
  },
  detailsGrid: {
    marginBottom: 8,
  },
  title: {
    fontSize: 20,
    lineHeight: "30px",
    fontWeight: 600,
    color: '#1d3054'
  },
  description: {
    fontSize: 14,
    lineHeight: "20px",
    color: '#4d4d4d'
  },
}))

export default function VacancyDetails() {
  const classes = useStyles()

  const navigate = useNavigate()

  const { alert, setAlert } = useAlert()

  const { modal, setModal } = useModal()

  const { vacancies, setVacancies } = useVacancies()

  const [vacancy, setVacancy] = useState<IVacancy>({} as IVacancy)

  const [requiredDocuments, setRequiredDocuments] = useState<boolean>(false)

  const [takeResponsibility, setTakeResponsibility] = useState<boolean>(false)

  useEffect(() => {
    setVacancy(JSON.parse(localStorage.getItem("selectedVacancy") as string))
    localStorage.setItem('slide', "0")
  }, [])

  const disableTakeResponsibilityButton = () => {
    if (
      !vacancy?.emailCandidato?.length ||
      !vacancy?.nomeCandidato?.length ||
      vacancy?.cpfCandidato?.length < 11 ||
      checkSpecialCharactersOrNumbers(vacancy?.nomeCandidato) ||
      !validateCpf(vacancy?.cpfCandidato)
    ) {
      return true
    }
  }

  const handleDeleteVacancy = () => {
    localStorage.setItem("backPath", "/auth/detalhes-vaga")
    setModal({ ...modal, deleteVacancy: true })
  }

  const renderVacanciesInProgressButton = () => {
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: "auto" }}>
        <Typography variant='body2' className={classes.documentsOnboarding}>
          Onboarding de documentos:
        </Typography>

        <VacanciesButton
          filled
          buttonText='Vagas em andamento'
          helperText='Vagas assumidas por você.'
          disabled={!vacancies?.vagasEmAndamento?.length}
          onClick={() => navigate("/auth/vagas-em-andamento")}
          firstIcon={<MdOutlinePersonPin size={24} color='#FFF' />}
          lastIcon={<FaChevronCircleRight size={24} color='#FFF' />}
        />
      </Grid>
    )
  }

  const handleCpfValidation = () => {
    if (vacancy?.cpfCandidato?.length === 11) {
      if (!validateCpf(vacancy?.cpfCandidato)) return true
    }
  }

  const apiSubmit = async () => {
    setAlert({ ...alert, waitingResponse: true })

    let response = await TakeVacancy(
      localStorage.getItem("externalId") as string,
      vacancy?.nrChamado,
      vacancy?.cpfCandidato,
      vacancy?.nomeCandidato,
      vacancy?.emailCandidato
    )

    if (response?.status >= 200 && response?.status < 300) {
      localStorage.setItem("selectedVacancy", JSON.stringify(vacancy))

      let costCentersResponse = await GetSections(localStorage.getItem("externalId") as string)

      if (costCentersResponse?.status >= 200 && costCentersResponse?.status < 300) {
        localStorage.setItem("sectionCenters", JSON.stringify(costCentersResponse.data))

        let vacanciesResponse = await requestVacanciesBySections()

        setVacancies(vacanciesResponse)

        setAlert({ ...alert, waitingResponse: false })

        swalAlert(
          "success",
          `A vaga <strong>${vacancy?.nome}</strong> foi assumida!`,
          "OK, ENTENDI"
        )

        setTimeout(() => navigate("/auth/documentos"), 1000)
      } else {
        setAlert({ ...alert, waitingResponse: false })
      }
    } else {
      setAlert({ ...alert, waitingResponse: false })

      swalAlert("error", response, "TENTAR NOVAMENTE")
    }
  }

  return (
    <MobileContainer style={{ padding: 0, backgroundColor: "#F3F4F6" }}>
      <Grid style={{ backgroundColor: "#1C2F54" }}>
        <Header />
      </Grid>
      <Grid style={{ marginBottom: 16 }}>
        <GoBackHeader
          onGoBack={() => navigate("/auth/novas-vagas")}
        />
      </Grid>

      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className={classes.title}>
          Deseja assumir essa vaga?
        </Typography>
        <Typography variant="body2" className={classes.description}>
          Leia as informações e veja se está tudo correto antes de assumir.
        </Typography>
      </Grid>

      <Grid style={{ marginRight: 16, marginLeft: 16 }}>
        <MainPaper
          height='100%'
          overflow
          position="static"
          transform="none"
        >
          <Grid container className={classes.paperContainer}>
            <>
              <Grid container className={classes.card}>
                <VacancyInfo />

                <Grid container>
                  <Typography variant="h6" className={classes.title}>
                    Empregado
                  </Typography>

                  <TextField
                    required
                    fullWidth
                    size='small'
                    margin='dense'
                    variant='outlined'
                    autoComplete='off'
                    label=''
                    value={vacancy?.nomeCandidato}
                    error={checkSpecialCharactersOrNumbers(vacancy?.nomeCandidato)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setVacancy({ ...vacancy, nomeCandidato: event.target.value })
                    }
                    helperText={
                      checkSpecialCharactersOrNumbers(vacancy?.nomeCandidato) &&
                      "Caracteres especiais ou números não são permitidos."
                    }
                  />
                </Grid>
                <Grid container>

                  <Typography variant="h6" className={classes.title}>
                    E-mail
                  </Typography>

                  <TextField
                    required
                    fullWidth
                    size='small'
                    label=''
                    margin='dense'
                    variant='outlined'
                    autoComplete='off'
                    value={vacancy?.emailCandidato}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setVacancy({ ...vacancy, emailCandidato: event.target.value })
                    }
                  />
                </Grid>


                <Grid container>

                  <Typography variant="h6" className={classes.title}>
                    CPF
                  </Typography>
                  <PatternFormat
                    required
                    fullWidth
                    type='tel'
                    label=''
                    size='small'
                    margin='dense'
                    variant='outlined'
                    autoComplete='off'
                    customInput={TextField}
                    format='###.###.###-##'
                    value={vacancy?.cpfCandidato}
                    error={handleCpfValidation()}
                    helperText={handleCpfValidation() && "Digite um CPF válido."}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setVacancy({ ...vacancy, cpfCandidato: removeCpfFormat(event.target.value) })
                    }
                  />
                </Grid>
                <MainButton
                  size='medium'
                  fullWidth={true}
                  onClick={apiSubmit}
                  style={{ marginBottom: 12, marginTop: 12, padding: 16 }}
                  disabled={disableTakeResponsibilityButton()}

                >
                  ASSUMIR VAGA
                </MainButton>

                <MainButton
                  size='medium'
                  fullWidth={true}
                  onClick={handleDeleteVacancy}
                  buttonStyle='errorOutlined'
                  style={{ padding: 16 }}
                >
                  DELETAR VAGA
                </MainButton>

              </Grid>
            </>
          </Grid>
        </MainPaper>
      </Grid>

      <BackdropLoading open={alert.waitingResponse} />
      <DeleteClearButton
        open={modal.deleteVacancy}
        close={() => setModal({ ...modal, deleteVacancy: false })}
        typeButton="deleteVacancy"
      />
    </MobileContainer>
  )
}
