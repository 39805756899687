import { GetVacanciesBySections } from "./api"

export const requestVacanciesBySections = async () => {
  let deCode = localStorage.getItem("externalId") as string

  let selectedSectionDescription = JSON.parse(
    localStorage.getItem("selectedSection") as string
  ).descricao

  let response = await GetVacanciesBySections(deCode, selectedSectionDescription)

  if (response?.status >= 200 && response?.status < 300) {
    localStorage.setItem("vacanciesBySection", JSON.stringify(response.data))
    return response.data
  }
}
