import React, { useContext, useState, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import ValeTransporteForm from './ValeTransporteForm';
import ValeTransporteCard from './ValeTransporteCard';
import { ValeTransporte, Ficha } from '../typesFicha';
import { Button, Grid, FormControl, FormControlLabel, Checkbox, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { deleteValeTransporte } from '../../../../services/api';
import { swalAlert } from '../../../../helpers/swalAlert';

interface Props {
  onSave: (componentName: string, data: Partial<Ficha>) => void;
  onExit: (componentName: string, data: Partial<Ficha>) => void;
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

const ValeTransporteMain: React.FC<Props> = ({ onSave, onExit, setLoading, isLoading }) => {
  const context = useContext(FichaContext);

  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [formData, setFormData] = useState<Partial<Ficha>>({});
  const [needsValeTransporte, setNeedsValeTransporte] = useState<boolean>(false);

  useEffect(() => {
    if (ficha.vtExiste === 'true') {
      setNeedsValeTransporte(true);
    } else if (ficha.vtExiste === 'false') {
      setNeedsValeTransporte(false);
    } else {
      setNeedsValeTransporte(true);
    }
  }, []);

  if (!context) {
    return <div>Contexto não encontrado</div>;
  }

  const { ficha, updateFicha } = context;

  const handleAddLine = () => {
    setEditingIndex(null);
    setIsEditing(true);
  };

  const handleEditLine = (index: number) => {
    setEditingIndex(index);
    setIsEditing(true);
  };

  const handleSaveLine = async (data: ValeTransporte) => {
    let updatedValeTransporte = [...(ficha.valeTransporte || [])];

    const existingLineIndex = updatedValeTransporte.findIndex(vt => vt.linha === data.linha);
    if (existingLineIndex !== -1 && existingLineIndex !== editingIndex) {
      swalAlert(
        "error",
        `A linha ${data.linha} já está cadastrada.`,
        "FECHAR"
      );
      return;
    }

    if (editingIndex !== null) {
      updatedValeTransporte[editingIndex] = data;
    } else {
      updatedValeTransporte.push(data);
    }

    const updatedFicha = {
      valeTransporte: updatedValeTransporte,
      vtExiste: updatedValeTransporte.length > 0 ? 'true' : 'false'
    };

    try {
      await updateFicha(updatedFicha);
      setFormData(updatedFicha);
      setIsEditing(false);
    } catch (error) {
      console.error('Erro ao atualizar ficha:', error);
    }
  };

  const handleDeleteLine = async (index: number) => {
    const cpf = ficha.cpf;
    const linha = ficha.valeTransporte[index].linha;

    try {
      if (!cpf || !linha) {
        console.error('CPF ou linha ausentes');
        return;
      }
      const response = await deleteValeTransporte(cpf, linha);

      if (response.status >= 200 && response.status < 300) {
        let updatedValeTransporte = [...(ficha.valeTransporte || [])];
        updatedValeTransporte.splice(index, 1);

        const updatedFicha = {
          ...ficha,
          valeTransporte: updatedValeTransporte,
          vtExiste: updatedValeTransporte.length > 0 ? 'true' : 'false'
        };

        await updateFicha(updatedFicha);
        setFormData(updatedFicha);
        setIsEditing(false);
        swalAlert(
          "success",
          `<strong>Linha de ônibus excluída com sucesso</strong><br />Os dados da linha <strong>${linha}</strong> foram excluídos.`,
          "FECHAR"
        );
      } else {
        console.error('Erro ao deletar linha:', response);
        swalAlert(
          "error",
          `Não foi possível remover a linha ${linha}`,
          "TENTAR NOVAMENTE"
        );
      }
    } catch (error) {
      console.error('Erro ao deletar linha:', error);
      swalAlert(
        "error",
        `Não foi possível remover a linha ${linha}`,
        "TENTAR NOVAMENTE"
      );
    }
  };

  const handleValeTransporteChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const updatedFicha = await updateFicha({ valeTransporte: [], vtExiste: 'false' });
      setFormData({ valeTransporte: [] });
      setNeedsValeTransporte(false);
    } else {
      setNeedsValeTransporte(true);
    }
  };

  const handleSave = () => {
    onSave('valeTransporte', formData);
  };

  const handleExit = () => {
    onExit('onExit', formData);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const buttonDisabled = () => {
    return (!ficha.valeTransporte || !ficha.valeTransporte.length) && needsValeTransporte;
  };

  if (isEditing) {
    return (
      <ValeTransporteForm
        title={editingIndex !== null ? "Editar linha" : "Adicionar linha"}
        initialData={
          editingIndex !== null ? ficha.valeTransporte[editingIndex] : {
            empregadoId: 0,
            linha: '',
            valorTarifa: '',
            quantidade: '',
            operadora: ''
          }
        }
        onSave={handleSaveLine}
        onCancel={handleCancel}
        onDelete={editingIndex !== null ? () => handleDeleteLine(editingIndex) : undefined}
      />
    );
  }

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          Vale Transporte
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>

      <div
        onClick={needsValeTransporte ? handleAddLine : undefined}
        className={`buttonAttach ${!needsValeTransporte ? 'checkboxDisabled' : ''}`}
        tabIndex={needsValeTransporte ? 0 : -1}
        role="button"
        onKeyDown={(e) => {
          if (needsValeTransporte && e.key === 'Enter') { handleAddLine() };
        }}
        aria-disabled={!needsValeTransporte}
      >
        <AddCircleIcon style={{ fontSize: 50, color: '#1D3054' }} />
        <Grid>
          <h2>Adicionar linha de ônibus</h2>
          <p>Clique aqui para adicionar uma linha de ônibus</p>
        </Grid>
      </div>

      {ficha.valeTransporte.length === 0 && (
        <form className='formComponenteCheckbox'>
          <FormControl className='formControl' component="fieldset">
            <FormControlLabel
              control={<Checkbox checked={!needsValeTransporte} onChange={handleValeTransporteChange} />}
              label="Não preciso de vale transporte"
            />
          </FormControl>
        </form>
      )}

      {ficha.valeTransporte.length > 0 && (
        <div>
          {ficha.valeTransporte.map((vt, index) => (
            <ValeTransporteCard
              key={index}
              valeTransporte={vt}
              onEdit={() => handleEditLine(index)}
            />
          ))}
        </div>
      )}

      <div className='divButtons'>
        <Button
          className='buttonSaveNext'
          variant="contained"
          onClick={handleSave}
          disabled={buttonDisabled() || isLoading}
        >
          Próxima Etapa
        </Button>
        <Button className='buttonSaveExit' variant="contained" onClick={handleExit} disabled={buttonDisabled() || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default ValeTransporteMain;
