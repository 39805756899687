import React from "react";
import { makeStyles } from '@mui/styles';
import { LinearProgress, Typography } from '@mui/material';
import { calculateProgressBar, formatDateToBrazilianPattern, showFormatedCpf } from "../helpers/format";

const useStyles = makeStyles(() => ({
  cardVacancy: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    padding: 16,
    paddingBottom: 12,
    borderRadius: 8,
    backgroundColor: '#fff',
    boxShadow: "0px 2px 4px -2px rgba(77, 77, 77, 0.06), 0px 4px 8px -2px rgba(77, 77, 77, 0.10)",
    marginBottom: 8
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleVacancy: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 700,
    color: '#1d3054'
  },
  itemVacancy: {
    fontSize: 12,
    lineHeight: "18px",
    fontWeight: 400,
    color: '#1d3054',
    marginBottom: 4
  }
}));

interface Empregado {
  nome: string;
  cpf: string;
}

interface VacancyDetails {
  nome: string;
  nrChamado: string;
  dataAdmissao: string;
  empregado: Empregado;
}

interface TitleVacancyDetailsProps {
  vacancyDetails: VacancyDetails;
  filledDocuments?: number;
  totalDocuments?: number;
  disableProgress?: boolean;
}

const TitleVacancyDetails: React.FC<TitleVacancyDetailsProps> = ({ vacancyDetails, filledDocuments = 0, totalDocuments = 1, disableProgress }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardVacancy}>
      <div className={classes.titleContainer}>
        <Typography variant="body2" className={classes.titleVacancy}>
          {vacancyDetails.nome}
        </Typography>
        {!disableProgress &&
          <Typography variant="body2" className={classes.titleVacancy}>
            {filledDocuments}/{totalDocuments} etapas
          </Typography>}
      </div>
      {!disableProgress && (
        <LinearProgress
          color="primary"
          variant="determinate"
          style={{ borderRadius: 8, height: 8, marginTop: 12, marginBottom: 12 }}
          value={totalDocuments ? calculateProgressBar(filledDocuments, totalDocuments) : 0}
        />
      )}
      <Typography variant="body2" className={classes.itemVacancy}>
        <strong>Nº de chamado:</strong> {vacancyDetails.nrChamado}
      </Typography>
      <Typography variant="body2" className={classes.itemVacancy}>
        <strong>Data de admissão: </strong>{formatDateToBrazilianPattern(vacancyDetails?.dataAdmissao)}
      </Typography>
      <Typography variant="body2" className={classes.itemVacancy}>
        <strong>Empregado:</strong> {vacancyDetails.empregado?.nome || 'N/A'}
      </Typography>
      <Typography variant="body2" className={classes.itemVacancy}>
        <strong>CPF:</strong> {showFormatedCpf(vacancyDetails.empregado?.cpf) || 'N/A'}
      </Typography>
    </div>
  );
}

export default TitleVacancyDetails;
