import { createContext, useState, ReactNode } from "react"

import { IVacancy } from "../@types/Vacancies"

export interface IVacanciesBySection {
  secaoDescricao: string
  novasVagas: Array<IVacancy>
  vagasEmAndamento: Array<IVacancy>
}

type VacanciesTypes = {
  vacancies: IVacanciesBySection
  setVacancies: (vacancies: IVacanciesBySection) => void
}

type VacanciesTypesProps = {
  children: ReactNode
}

export const VacanciesContext = createContext({} as VacanciesTypes)

const VacanciesProvider = (props: VacanciesTypesProps) => {
  const [vacancies, setVacancies] = useState<IVacanciesBySection>({} as IVacanciesBySection)

  return (
    <VacanciesContext.Provider
      value={{
        vacancies,
        setVacancies,
      }}
    >
      {props.children}
    </VacanciesContext.Provider>
  )
}

export default VacanciesProvider
