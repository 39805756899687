import React, { useContext, useState } from 'react';
import { Dependente } from '../typesFicha';
import { Button, Grid, TextField, Typography, RadioGroup, FormControlLabel, Radio, FormControl, Select, MenuItem, Modal } from '@mui/material';
import { FichaContext } from '../../../../context/FichaContext';
import { FiAlertCircle } from 'react-icons/fi';
import { removeCpfFormat } from '../../../../helpers/format';
import { validateCpf } from '../../../../helpers/validation';

interface DependenteFormProps {
  title: string;
  initialData: Dependente;
  onSave: (data: Dependente) => void;
  onCancel: () => void;
  onDelete?: () => void;
}

const DependenteForm: React.FC<DependenteFormProps> = ({ title, initialData, onSave, onCancel, onDelete }) => {
  const context = useContext(FichaContext);
  const [formData, setFormData] = useState<Dependente>(initialData);
  const [dependenteExist, setDependenteExist] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  if (!context) {
    return <div>Contexto não encontrado</div>
  }

  const { ficha } = context;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'cpf') {
      checkCpfExists(value);
    }
  };

  const checkCpfExists = (cpf: string) => {
    const exists = ficha.dependente.some(d => d.cpf === cpf);
    setDependenteExist(exists);
  };

  const handleSelectChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name as string]: value as string,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!dependenteExist) {
      onSave(formData);
    }
  };

  const confirmDelete = () => {
    setIsCancelModalOpen(true);
  };

  const handleDelete = () => {
    onDelete && onDelete();
    setIsCancelModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsCancelModalOpen(false);
  };

  const handleCpfValidation = () => {
    if (formData.cpf) {
      const cpfSemFormatacao = removeCpfFormat(formData.cpf);
      return cpfSemFormatacao.length > 0 ? !validateCpf(cpfSemFormatacao) : false;
    } else {
      return false;
    }
  };

  const allFieldsFilled = () => {
    return (
      formData.nome &&
      formData.dataNascimento &&
      formData.grauParentesco &&
      formData.cpf &&
      formData.irrf
    );
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          {title}
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit}>
        <div className='formComponente'>
          <Grid className='gridComponente'>
            <Typography variant="h6" className='titleTypography'>
              Nome completo <span className='asterisk'>*</span>
            </Typography>
            <TextField
              size='small'
              required
              name="nome"
              value={formData.nome || ''}
              onChange={handleChange}
              className='textField'
            />
          </Grid>

          <Grid className='gridComponente'>
            <Typography variant="h6" className='titleTypography'>
              Data de nascimento <span className='asterisk'>*</span>
            </Typography>
            <TextField
              size='small'
              required
              name="dataNascimento"
              type="date"
              value={formData.dataNascimento || ''}
              onChange={handleChange}
              className='textField'
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid className='gridComponente'>
            <Typography variant="h6" className='titleTypography'>
              CPF <span className='asterisk'>*</span>
            </Typography>
            <TextField
              size='small'
              required
              name="cpf"
              value={formData.cpf}
              onChange={handleChange}
              helperText={(dependenteExist ? 'CPF já existente' : '') || (handleCpfValidation() && "Digite um CPF válido.")}
              label=""
              error={dependenteExist || handleCpfValidation()}
              inputProps={{ maxLength: 14 }}
              className='textField'
            />
          </Grid>

          <Grid className='gridComponente'>
            <Typography variant="h6" className='titleTypography'>
              Grau de parentesco <span className='asterisk'>*</span>
            </Typography>
            <FormControl className='formControl' required>
              <Select
                value={formData.grauParentesco}
                onChange={(e) => handleSelectChange(e as React.ChangeEvent<{ name?: string; value: unknown }>)}
                name="grauParentesco"
                className='textField'
                size='small'
              >
                <MenuItem value={"Filho(a) válido"}>Filho(a) válido</MenuItem>
                <MenuItem value={"Filho(a) inválido"}>Filho(a) inválido</MenuItem>
                <MenuItem value={"Cônjuge"}>Cônjuge</MenuItem>
                <MenuItem value={"Companheiro(a)"}>Companheiro(a)</MenuItem>
                <MenuItem value={"Enteado(a)"}>Enteado(a)</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid className='gridComponente'>
            <Typography variant="h6" className='titleTypography'>
              Dedução IRRF <span className='asterisk'>*</span>
            </Typography>
            <FormControl className='formControl' component="fieldset" required>
              <RadioGroup
                className='radioGroup'
                row
                name="irrf"
                value={formData.irrf}
                onChange={handleChange}
              >
                <FormControlLabel className='formControlLabel' value="sim" control={<Radio />} label="Sim" />
                <FormControlLabel className='formControlLabel' value="não" control={<Radio />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </div>

        <div className='divButtons'>
          <Button className='buttonSaveNext' variant="contained" type="submit" disabled={(dependenteExist) || handleCpfValidation() || !allFieldsFilled()}>
            {title === 'Editar dependente' ? 'SALVAR' : 'ADICIONAR DEPENDENTE'}
          </Button>
          <Button className='buttonSaveExit' variant="contained" type="button" onClick={onCancel}>
            Cancelar
          </Button>
          {onDelete && (
            <Button
              className='buttonDelete'
              variant="contained"
              type="button"
              onClick={confirmDelete}
            >
              EXCLUIR DEPENDENTE
            </Button>
          )}
        </div>
      </form>

      <Modal
        open={isCancelModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="modalContainer">
          <FiAlertCircle
            size={48}
            color='#B91C1C'
            style={{
              marginBottom: 8,
            }}
          />
          <Typography className="modalTitle" variant="h6">
            Deseja excluir este dependente?
          </Typography>
          <Typography className="modalSubtitle" >
            Essa ação não pode ser desfeita.
          </Typography>
          <div className="modalButtons">
            <Button size='small' variant="contained" color="error" onClick={handleDelete} className="modal-button-delete">
              Excluir dependente
            </Button>
            <Button size='small' variant="contained" color="primary" onClick={handleCloseModal} className="modal-button-cancel">
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DependenteForm;
