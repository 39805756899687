import { ChangeEvent, useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"

import { Alert, AlertTitle, Grid, Theme } from "@mui/material"

import { makeStyles } from "@mui/styles"

import useAlert from "../../hooks/useAlert"
import Header from "../../components/Header"
import { sortByCV } from "../../helpers/sort"
import MainPaper from "../../components/MainPaper"
import { IVacancy } from "../../@types/Vacancies"
import useVacancies from "../../hooks/useVacancies"
import SearchButton from "../../components/SearchButton"
import SortVacancies from "../../components/SortVacancies"
import SectionsButton from "../../components/SectionsButton"
import BackdropLoading from "../../components/BackdropLoading"
import MobileContainer from "../../components/MobileContainer"
import { GetDocumentsQuantityByVacancy } from "../../services/api"
import { IVacanciesBySection } from "../../context/VacanciesContext"
import { requestVacanciesBySections } from "../../services/getVacancies"
import VacancyInProgressCard from "../../components/VacancyInProgressCard"
import NavigationMenu from "../../components/VacanciesMenuButton"

export default function VacanciesInProgress() {
  const navigate = useNavigate()

  const { alert, setAlert } = useAlert()

  const { vacancies, setVacancies } = useVacancies()

  const [searchFilter, setSearchFilter] = useState<string>("")

  const [vacancyType, setVacancyType] = useState<IVacancy[]>(vacancies?.vagasEmAndamento)

  const [selectedOption, setSelectedOption] = useState<string>("Número de chamado");

  const [filteredSection, setFilteredSection] = useState(
    JSON.parse(localStorage.getItem("selectedSection") as string)
  )

  useEffect(() => {
    setVacancyType(vacancies?.vagasEmAndamento)
  }, [vacancies])

  const [documentsSentAndTotal, setDocumentsSentAndTotal] = useState<Array<any>>([])

  useEffect(() => {
    if (vacancies?.secaoDescricao === filteredSection?.descricao) {
      (async function loadDocs() { await getDocumentSentByVacancy(vacancies) }());
    } else {
      setAlert({ ...alert, waitingResponse: true })

      const getVacancies = async () => {
        let response = await requestVacanciesBySections()

        if (response?.vagasEmAndamento?.length === 0) {
          navigate("/auth")

          setAlert({ ...alert, waitingResponse: false });
        } else {
          setVacancies(response);

          setVacancyType(sortByCV(response?.vagasEmAndamento));
          setSelectedOption("Número de chamado");

          (async function loadDocs() { await getDocumentSentByVacancy(response) }());

          setAlert({ ...alert, waitingResponse: false });
        }
      }
      localStorage.setItem('slide', "0");
      (async function loadDocs() { await getVacancies() }());
    }

    if (window.location.pathname.includes("vagas-em-andamento")) {
      localStorage.setItem("backPath", "/auth/vagas-em-andamento")
      localStorage.setItem("vacancyType", "vagasEmAndamento")
    }
  }, [filteredSection])

  const handleDocumentsSent = async (vacancyNumbers: string) => {
    let response = await GetDocumentsQuantityByVacancy(vacancyNumbers)


    if (response?.status >= 200 && response?.status < 300) {
      setDocumentsSentAndTotal(response.data)
    }
  }

  const getDocumentSentByVacancy = async (vacancy: IVacanciesBySection) => {
    let vacancyNumbers = ""

    vacancy?.vagasEmAndamento?.forEach((item: IVacancy) => {
      vacancyNumbers += `${item.nrChamado},`
    });

    (async function loadDocs() { await handleDocumentsSent(vacancyNumbers.slice(0, vacancyNumbers.length - 1)) }());
  }

  const handleAttachDocuments = async (vacancy: IVacancy) => {
    localStorage.setItem("selectedVacancy", JSON.stringify(vacancy))

    navigate("/auth/documentos")
  }

  const searchVacancy = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value)
  }

  const clearSearchFilter = () => {
    setSearchFilter("")
  }

  const searchFilterResult = !searchFilter
    ? vacancyType
    : vacancyType?.filter(
      vacancy =>
        vacancy.nome.toLowerCase().includes(searchFilter.toLocaleLowerCase().trim()) ||
        vacancy.nrChamado.includes(searchFilter.trim())
    )

  const renderNoVacancyFound = () => (
    <Alert severity='info' variant='outlined'>
      <AlertTitle>
        <strong>Vaga não encontrada</strong>
      </AlertTitle>
      Não existe nenhuma vaga para <strong>{searchFilter}</strong> nesta obra.
    </Alert>
  )

  const useStyles = makeStyles((theme: Theme) => ({
    greetingsMessage: {
      color: "#FFF",
    },
    divider: {
      height: 2,
      width: "100%",
      marginTop: 16,
      background: "#FFF",
    },
    paperContainer: {
      padding: 16,
      /* overflow: "auto", */
      height: "100zh",
      justifyContent: "center",
      backgroundColor: "#E8E8E8",
    },
    cardGrid: {
      marginBottom: 12,
      position: "relative",
    },
  }))

  const classes = useStyles()

  return (
    <MobileContainer blueBackground style={{ padding: 0, backgroundColor: "#FFF" }}>
      <Header />
      <MainPaper
        height='100%'
        overflow
        position="static"
        transform="none"
      >

        <Grid style={{ marginBottom: 24, paddingRight: 16, paddingLeft: 16 }}>
          <NavigationMenu activePage="vagas-em-andamento" />
        </Grid>

        <Grid style={{ marginBottom: 24, paddingRight: 16, paddingLeft: 16 }}>
          <SearchButton
            value={searchFilter}
            onChange={searchVacancy}
            clearInput={clearSearchFilter}
          />
        </Grid>

        <Grid style={{ marginBottom: 16, paddingRight: 16, paddingLeft: 16 }}>
          <h2 style={{ color: "#1D3054", marginBottom: 8, fontSize: 16 }}>Seção/Departamento</h2>
          <SectionsButton filteredSection={filteredSection} setFilteredSection={setFilteredSection} />
        </Grid>

        <Grid container style={{ marginBottom: 12, paddingRight: 16, paddingLeft: 16 }}>
          <SortVacancies vacancyType={vacancyType} setVacancyType={setVacancyType} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
        </Grid>

        <Grid style={{
          backgroundColor: "#E8E8E8",
        }}>
          <h2 style={{ color: "#1D3054", marginBottom: 8, paddingTop: 16, fontSize: 20, paddingRight: 16, paddingLeft: 16 }}>Vagas em andamento</h2>
          <h4 style={{ color: "#4D4D4D", fontSize: 14, paddingBottom: 8, paddingRight: 16, paddingLeft: 16 }}>
            Veja abaixo as vagas que você assumiu.
          </h4>
        </Grid>

        <Grid container className={classes.paperContainer}>
          {searchFilterResult?.length === 0 && vacancyType?.length !== 0 ? (
            renderNoVacancyFound()
          ) : vacancyType?.length === 0 ? (
            <Alert severity='info' variant='outlined'>
              <AlertTitle>
                <strong>Vaga não encontrada</strong>
              </AlertTitle>
              Não existe nenhuma vaga nesta obra.
            </Alert>
          ) : (
            searchFilterResult?.map((vacancy, index) => (
              <Grid container key={vacancy.id} className={classes.cardGrid}>
                <VacancyInProgressCard
                  vacancy={vacancy}
                  attachDocuments={() => handleAttachDocuments(vacancy)}
                  totalDocuments={
                    documentsSentAndTotal?.length > 0
                      ? documentsSentAndTotal[index]?.qtdeDocumentoTotal
                      : 0
                  }
                  sentDocuments={
                    documentsSentAndTotal?.length > 0
                      ? documentsSentAndTotal[index]?.qtdeDocumentoEnviadoCentral
                      : 0
                  }
                  attachedDocuments={
                    documentsSentAndTotal?.length > 0
                      ? documentsSentAndTotal[index]?.qtdeDocumentoAnexado
                      : 0
                  }
                  sentToCentralEnabled={
                    documentsSentAndTotal?.length > 0
                      ? documentsSentAndTotal[index]?.habilitaEnvio
                      : 0
                  }
                />
              </Grid>
            ))
          )}
        </Grid>

        <BackdropLoading open={alert.waitingResponse} />
      </MainPaper>
    </MobileContainer>
  )
}
