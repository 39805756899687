import { useNavigate } from "react-router-dom"

import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material"

import { makeStyles } from "@mui/styles"

import MainButton from "./MainButton"

interface DevicePermissionProps {
  open: boolean
  close: () => void
}

export default function DevicePermission({ open, close }: DevicePermissionProps) {
  const useStyles = makeStyles({
    title: {
      textAlign: "center",
    },
  })

  const classes = useStyles()

  const navigate = useNavigate()

  const handleAccept = () => {
    localStorage.setItem("permissaoDispositivo", "Sim")
    setTimeout(close, 500)
  }

  const handleDeny = () => {
    localStorage.setItem("permissaoDispositivo", "Não")
    navigate("/auth/documentos")
  }

  return (
    <>
      <Dialog open={open}>
        <DialogTitle className={classes.title}>
          <strong>PERMISSÃO REQUERIDA</strong>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Para fazer o envio dos documentos será necessária a permissão do uso de sua
            <strong> câmera</strong> e<strong> galeria de arquivos</strong>.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <MainButton size='small' onClick={handleDeny} buttonStyle='error'>
            Negar
          </MainButton>

          <MainButton size='small' onClick={handleAccept} buttonStyle='contained'>
            Permitir
          </MainButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
