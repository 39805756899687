import React, { useState, useContext, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import { FormControl, FormControlLabel, RadioGroup, Radio, Button, Typography, Grid } from '@mui/material';
import { Ficha } from '../typesFicha';

interface Props {
  onSave: (componentName: string, data: Partial<Ficha>) => void;
  onExit: (componentName: string, data: Partial<Ficha>) => void;
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

const VaVr: React.FC<Props> = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);
  const [formData, setFormData] = useState({
    tipoVaVr: fichaContext?.ficha.tipoVaVr || '',
  });

  const [VaVrValida, setVaVrValida] = useState(formData.tipoVaVr !== null);

  useEffect(() => {
    setVaVrValida(!!formData.tipoVaVr);
  }, [formData.tipoVaVr]);

  if (!fichaContext) {
    return null;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      tipoVaVr: value,
    });
    setVaVrValida(!!value);
  };

  const handleSave = () => {
    onSave('VaVr', formData);
  };

  const handleExit = () => {
    onExit('onExit', formData);
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          VA/VR
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>
      <form className='formComponente'>
        <Typography variant="h6" className='titleTypography'>
          Prefere vale alimentação ou vale refeição? <span className='asterisk'>*</span>
        </Typography>
        <FormControl className='formControl' component="fieldset" required>
          <RadioGroup name="tipoVaVr" value={formData.tipoVaVr} onChange={handleChange}>
            <FormControlLabel value='VA' control={<Radio />} label="Vale Alimentação" />
            <FormControlLabel value='VR' control={<Radio />} label="Vale Refeição" />
          </RadioGroup>
        </FormControl>
      </form>
      <div className='divButtons'>
        <Button className='buttonSaveNext' variant="contained" onClick={handleSave} disabled={!VaVrValida || isLoading}>
          Próxima Etapa
        </Button>
        <Button className='buttonSaveExit' variant="contained" onClick={handleExit} disabled={!VaVrValida || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default VaVr;
