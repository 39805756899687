import { Grid, Typography, Tooltip } from "@mui/material"

import { useTheme } from "@mui/material/styles"

import { makeStyles } from "@mui/styles"

import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa"

import { AccountCircleOutlined } from "@mui/icons-material"
import UploadIcon from '@mui/icons-material/Upload';
import ConfirmVacanciesLink from "./ConfirmVacanciesLink"

import MainButton from "./MainButton"
import { formatToBrazilianCurrency } from "../helpers/format"
import { useState } from "react"

interface VacancyCardProps {
  job: string
  nomeCandidato: string
  cv: string
  status: string
  horario: string
  salario: number
  linkVaga: boolean
  onClick: () => void
}

export default function VacancyCard({ job, nomeCandidato, cv, status, horario, salario, linkVaga, onClick }: VacancyCardProps) {
  const theme = useTheme()

  const [showDetails, setShowDetails] = useState(false)
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  const handleConfirmModalOpen = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };

  const handleVacancyAccess = () => {
    if (linkVaga) {
      handleConfirmModalOpen();
    } else {
      onClick();
    }
  };

  const handleDetailsClick = () => {
    setShowDetails(!showDetails)
  }

  const borderColor = () => {
    if (status !== "Cancelada") {
      return theme.palette.primary.main
    } else {
      return theme.palette.secondary.main
    }
  }

  const vacancyStatus = () => {
    if (status !== "Cancelada") {
      return "#000"
    } else {
      return theme.palette.secondary.main
    }
  }

  const disableButton = () => {
    if (status !== "Ativa") {
      return true
    } else {
      return false
    }
  }

  const jobAndPersonIconColor = () => {
    if (status !== "Cancelada") {
      return "primary"
    } else {
      return "secondary"
    }
  }

  const useStyles = makeStyles({
    card: {
      display: "flex",
      padding: 12,
      borderRadius: 8,
      border: "none",
      background: "#FFF",
      borderColor: borderColor(),
      opacity: status === "Em andamento" ? 0.4 : 1,
    },
    job: {
      marginLeft: 8,
      fontWeight: 700,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      color: "#1D3054",
    },
    vacancyNumber: {
      color: "#1D3054",
      fontWeight: 700,
    },
    responsible: {
      fontSize: 12,
      fontWeight: 700,
      color: "#1D3054",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    cv: {
      fontWeight: 700,
      color: "#1D3054",
    },
    status: {
      fontWeight: 700,
      color: vacancyStatus(),
    },
    spanData: {
      color: "#4D4D4D",
    },
    linkInfo: {
      color: "#A16207",
      background: "#FEF9C3",
      fontWeight: 700,
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 200,
      gap: 4,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
      paddingBottom: 4,
      marginTop: 8,
      marginBottom: 8,
    },
  })

  const classes = useStyles()

  return (
    <Grid container className={classes.card}>
      <Grid container style={{ marginBottom: 4 }}>
        <AccountCircleOutlined color={jobAndPersonIconColor()} />

        <Grid item xs={8} sm={8} md={8}>
          <Tooltip title={job} placement='top' arrow enterTouchDelay={0}>
            <Typography variant='body1' className={classes.job}>
              {job}
            </Typography>
          </Tooltip>
        </Grid>

        <button
          onClick={handleDetailsClick}
          style={{ marginLeft: "auto", border: "none", background: "none" }}
        >
          {showDetails ? (
            <FaChevronCircleUp size={24} color='#E8E8E8' style={{ background: '#2C487E', borderRadius: 200, border: "none" }} />
          ) : (
            <FaChevronCircleDown size={24} color='#E8E8E8' style={{ background: '#2C487E', borderRadius: 200, border: "none" }} />
          )}
        </button>
      </Grid>

      <Grid container style={{ marginBottom: 8 }}>
        <Typography variant='caption' className={classes.cv}>
          Nº de chamado: <span className={classes.spanData}><strong>{cv}</strong></span>
        </Typography>
      </Grid>

      <Grid container style={{ marginBottom: 8 }}>
        <Typography variant='caption' className={classes.vacancyNumber}>
          Empregado: <span className={classes.spanData}><strong>{nomeCandidato}</strong></span>
        </Typography>
      </Grid>


      {status !== "Cancelada" && showDetails ? (
        <>
          <Grid container style={{ marginBottom: 8 }}>
            <Typography variant='caption' className={classes.vacancyNumber}>
              Salário: <span className={classes.spanData}><strong>{formatToBrazilianCurrency(salario)}</strong></span>
            </Typography>
          </Grid>

          <Grid container style={{ marginBottom: 8 }}>
            <Typography variant='caption' className={classes.vacancyNumber}>
              Horário: <span className={classes.spanData}><strong>{horario}</strong></span>
            </Typography>
          </Grid>
        </>
      ) : null}

      {linkVaga && (
        <Grid container style={{ marginBottom: 8 }}>
          <Typography variant='caption' className={classes.linkInfo}>
            <UploadIcon style={{ fontSize: 16 }} />
            Link de documentos enviado ao candidato
          </Typography>
        </Grid>
      )}

      <Grid container alignItems='center'>
        <MainButton
          noPadding
          onClick={handleVacancyAccess}
          disabled={disableButton()}
          style={{ margin: "auto", width: "100%", padding: 16 }}
        >
          ACESSAR VAGA
        </MainButton>
      </Grid>

      <ConfirmVacanciesLink
        open={isConfirmModalOpen}
        close={handleConfirmModalClose}
        onConfirm={onClick}
      />
    </Grid >
  )
}
