import React from "react";
import { Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

interface NavigationMenuProps {
  activePage: "novas-vagas" | "vagas-em-andamento";
}

export default function NavigationMenu({
  activePage
}: NavigationMenuProps) {
  const theme = useTheme();

  const useStyles = makeStyles({
    button: {
      border: "none",
      borderRadius: 0,
      padding: "10px 0px",
      flex: 1,
      fontWeight: 500,
      width: "100%",
      fontSize: "14px",
      color: theme.palette.text.primary,
      backgroundColor: "transparent",
      transition: "border-color 0.3s, font-weight 0.3s",
    },
    activeButton: {
      borderBottom: "5px solid",
      fontWeight: 700,
      borderBottomColor: theme.palette.primary.main,
    },
    container: {
      display: "flex",
    },
    buttonContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  });

  const navigate = useNavigate();
  const classes = useStyles();

  const handleNavigate = (page: "novas-vagas" | "vagas-em-andamento") => {
    if (activePage !== page) {
      navigate(`/auth/${page}`);
    }
  };

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item className={classes.buttonContainer}>
        <Button
          className={`${classes.button} ${activePage === "novas-vagas" ? classes.activeButton : ""
            }`}
          onClick={() => handleNavigate("novas-vagas")}
        >
          Vagas Direcionadas
        </Button>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Button
          className={`${classes.button} ${activePage === "vagas-em-andamento" ? classes.activeButton : ""
            }`}
          onClick={() => handleNavigate("vagas-em-andamento")}
        >
          Vagas em Andamento
        </Button>
      </Grid>
    </Grid>
  );
}
