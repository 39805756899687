import { ReactNode } from "react"

import { AppBar, Tab } from "@mui/material"

import { TabPanel, TabList, TabContext } from "@mui/lab"

import { useTheme } from "@mui/material/styles"

import { makeStyles } from "@mui/styles"

import useTabs from "../hooks/useTabs"

interface TabListProps {
  value: string
  label: string
  component: ReactNode
  disabled?: boolean
}

interface MainTabsProps {
  white?: boolean
  scrollable?: boolean
  marginTop?: string | number
  tabList: Array<TabListProps>
}

export default function MainTabs({ white, marginTop, tabList, scrollable }: MainTabsProps) {
  const theme = useTheme()

  const { tab, setTab } = useTabs()

  const useStyles = makeStyles({
    tabTitle: {
      fontSize: 16,
      fontWeight: 700,
      textTransform: "none",
      color: white ? "#FFF !important" : theme.palette.primary.main,
      "&:disabled": {
        opacity: 1,
        color: white ? "#FFF !important" : theme.palette.primary.main,
      },
    },
    tabContent: {
      padding: 0,
      marginTop: marginTop ? marginTop : "20%",
    },
    tabLabel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    tabLabelIcon: {
      marginLeft: 4,
    },
    tabScrollIcon: {
      color: "#000",
    },
  })

  const classes = useStyles()

  const changeTab = (event: any, newTab: string) => {
    return setTab(newTab)
  }

  return (
    <TabContext value={tab}>
      <AppBar elevation={0} position='static' style={{ backgroundColor: "transparent" }}>
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
        <TabList
          elevation={0}
          scrollButtons='auto'
          onChange={changeTab}
          variant={scrollable ? "scrollable" : "fullWidth"}
          TabScrollButtonProps={{ classes: { root: classes.tabScrollIcon } }}
          TabIndicatorProps={{
            style: { background: white ? "#FFF !important" : theme.palette.primary.main },
          }}
        >
          {tabList.map((title: TabListProps) => (
            <Tab
              key={title.value}
              value={title.value}
              label={title.label}
              disabled={title.disabled}
              className={classes.tabTitle}
            />
          ))}
        </TabList>
      </AppBar>

      {tabList.map((content: TabListProps) => (
        <TabPanel key={content.value} value={content.value} className={classes.tabContent}>
          {content.component}
        </TabPanel>
      ))}
    </TabContext>
  )
}
