import { useEffect, useState } from "react"

import { Grid, Typography } from "@mui/material"

import { makeStyles } from "@mui/styles"

import { AccountCircleOutlined } from "@mui/icons-material"

import { IVacancy } from "../@types/Vacancies"
import { formatDateToBrazilianPattern, formatToBrazilianCurrency } from "../helpers/format"

const useStyles = makeStyles(theme => ({
  job: {
    color: "#1D3054",
    marginLeft: 8,
    fontWeight: 700,
    marginBottom: 12,
    fontSize: 18,
  },
  vacancyTitle: {
    color: "#1D3054",
    fontWeight: 700,
    fontSize: 16,
  },
  vacancySubtitles: {
    color: "#4D4D4D",
    fontWeight: 400,
    textOverflow: "ellipsis",
    fontSize: 16,
  },
  detailsGrid: {
    marginBottom: 16,
    flexDirection: "column",
  },
}))

export default function VacancyInfo() {
  const classes = useStyles()

  const [vacancyInDetail, setVacancyInDetail] = useState<IVacancy>({} as IVacancy)

  useEffect(() => {
    setVacancyInDetail(JSON.parse(localStorage.getItem("selectedVacancy") as string))
  }, [])

  return (
    <>
      <Grid container style={{ marginBottom: 8 }}>
        <AccountCircleOutlined />

        <Typography variant='body1' className={classes.job}>
          {vacancyInDetail?.nome}
        </Typography>
      </Grid>

      <Grid
        container
        alignItems='left'
        justifyContent='space-between'
        className={classes.detailsGrid}
      >
        <Typography variant='body2' align='left' className={classes.vacancyTitle}>
          Número de chamado:
        </Typography>

        <Typography variant='body1' align='left' className={classes.vacancySubtitles}>
          {vacancyInDetail?.nrChamado}
        </Typography>

      </Grid>

      <Grid
        container
        alignItems='left'
        justifyContent='space-between'
        className={classes.detailsGrid}
      >
        <Typography variant='body2' align='left' className={classes.vacancyTitle}>
          Salário:
        </Typography>

        <Typography variant='body1' align='left' className={classes.vacancySubtitles}>
          {formatToBrazilianCurrency(vacancyInDetail?.salario)}
        </Typography>

      </Grid>

      <Grid
        container
        alignItems='left'
        justifyContent='space-between'
        className={classes.detailsGrid}
      >
        <Typography variant='body2' align='left' className={classes.vacancyTitle}>
          Seção/Dep:
        </Typography>

        <Typography variant='body1' align='left' className={classes.vacancySubtitles}>
          {vacancyInDetail?.secao}
        </Typography>
      </Grid>

      <Grid
        container
        alignItems='left'
        justifyContent='space-between'
        className={classes.detailsGrid}
      >

        <Typography variant='body2' className={classes.vacancyTitle}>Tipo de vaga:</Typography >

        <Typography variant='body1' className={classes.vacancySubtitles}>
          {vacancyInDetail?.tipoMaoDeObra}
        </Typography>
      </Grid>

      <Grid
        container
        alignItems='left'
        justifyContent='space-between'
        className={classes.detailsGrid}
      >
        <Typography variant='body2' className={classes.vacancyTitle}>Data de abertura:</Typography>
        <Typography variant='body1' className={classes.vacancySubtitles}>
          {formatDateToBrazilianPattern(vacancyInDetail?.dataAbertura)}
        </Typography>
      </Grid>

      <Grid
        container
        alignItems='left'
        justifyContent='space-between'
        className={classes.detailsGrid}
      >
        <Typography variant='body2' align='left' className={classes.vacancyTitle}>
          Data de admissão:
        </Typography>

        <Typography variant='body1' align='left' className={classes.vacancySubtitles}>
          {formatDateToBrazilianPattern(vacancyInDetail?.dataAdmissao)}
        </Typography>

      </Grid>


    </>
  )
}
