import { orderBy } from "lodash"
import { IVacancy } from "../@types/Vacancies"

export const sortByJob = (vacancies: IVacancy[]) => {
  return orderBy(vacancies, "nome")
}

export const sortByDate = (vacancies: IVacancy[]) => {
  return orderBy(vacancies, "dataAbertura")
}

export const sortByStatus = (vacancies: IVacancy[]) => {
  return orderBy(vacancies, "status")
}

export const sortByResponsible = (vacancies: IVacancy[]) => {
  return orderBy(vacancies, "responsavelRecolhimento")
}

export const sortByCV = (vacancies: IVacancy[]) => {
  return orderBy(vacancies, "nrChamado")
}
