import axios from "axios"

import Swal from "sweetalert2"

import { getToken, logout } from "./auth"
import { dataToBlob } from "../helpers/format"
import { ISections } from "../@types/Sections"
import { IDocuments } from "../@types/Documents"
import { IVacanciesBySection } from "../context/VacanciesContext"
import { Ficha } from "../pages/DocumentLink/FichaCadastral/typesFicha"

export const frontendEnvironment = () => {
  let url = window.location.hostname.split(".")[0]

  const qa = "administrativo-qa.direcional.com.br"
  const prod = "administrativo.direcional.com.br"
  const dev = "administrativo-dev.direcional.com.br"
  const stage = "administrativo-stage.direcional.com.br"
  const qaTestes = "administrativo-testes.direcional.com.br"

  if (url === "administrativo-qa") return qa

  if (url === "administrativo-stage") return stage

  if (url === "administrativo-testes") return qaTestes

  if (url === "administrativo") return prod

  return dev
}

const backendEnvironment = () => {
  let url = window.location.hostname.split(".")[0]

  const prod = "https://api.direcional.com.br/onboardingAdministrativo/v1/"
  const qa = "https://apihmg.direcional.com.br/onboardingAdministrativo/v1/"
  const dev = "https://apidev.direcional.com.br/onboardingAdministrativo/v1/"
  //const dev = "http://192.168.100.4:19000/api/administrativo"
  const stage = "https://apistage.direcional.com.br/onboardingAdministrativo/v1/"
  const qaTestes = "https://apitest.direcional.com.br/onboardingAdministrativo/v1/"

  if (url === "administrativo-qa") return qa

  if (url === "administrativo-stage") return stage

  if (url === "administrativo-teste") return qaTestes

  if (url === "administrativo") return prod

  return dev
}

const api = axios.create({
  baseURL: backendEnvironment(),
  headers: {
    client_id: "648627f4-aeaf-487b-828b-4e4afeb1dbb2",
  },
})

api.interceptors.request.use(async (config: any) => {
  const token = getToken()
  if (token) {
    config.headers.common["access_token"] = `${token}`
  }
  return config
})

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    // Sem internet
    if (!error.response) {
      Swal.fire({
        title:
          "<h5 style='color: #A93335; margin-top: 8px; margin-bottom: 0px'>Problemas de conexão</h5>",
        allowOutsideClick: false,
        confirmButtonColor: "#29333C",
        confirmButtonText: "        TENTAR NOVAMENTE        ",
        text: "Parece que você está sem internet, verique sua conexão e tente novamente.",
      })
      return Promise.reject(error)
    }
    // Não autenticado
    if (error.response?.status === 401) {
      Swal.fire({
        title:
          "<h5 style='color: #A93335; margin-top: 8px; margin-bottom: 0px'>Acesso expirado</h5>",
        allowOutsideClick: false,
        confirmButtonColor: "#29333C",
        confirmButtonText: "        NOVO ACESSO        ",
        text: "Faça novamente o acesso a sua conta e ative um novo código de verificação.",
      }).then(() => {
        logout()
        ;(window as Window).location = "/"
      })
      return Promise.reject(error)
    }
    // Erro no servidor
    if (error.response?.status >= 500 && error.response?.status < 600) {
      Swal.fire({
        title:
          "<h5 style='color: #A93335; margin-top: 8px; margin-bottom: 0px'>Falha de solicitação</h5>",
        allowOutsideClick: false,
        confirmButtonColor: "#29333C",
        confirmButtonText: "        TENTAR NOVAMENTE        ",
        text: "O servidor encontrou um erro temporário e não pôde concluir sua solicitação, tente novamente.",
      })
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }
)

export const UserLogin = async (userId: string, password: string, tokenCloud: string) => {
  try {
    let response = await api.post("/Autenticacao/LoginUsuario", {
      idUsuario: userId,
      senha: password,
      captchaCode: tokenCloud,
    })

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    if (!error.response) {
      return "networkError"
    } else {
      return error.response?.data
    }
  }
}

export const UserValidate = async (userId: string, password: string, code: string) => {
  try {
    let response = await api.post("/Autenticacao/ValidaUsuario", {
      senha: password,
      idUsuario: userId,
      codigoVerificacao: code,
      aceiteTermos: [0],
    })

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    return error.response?.data
  }
}

export const TokenValidation = async () => {
  try {
    let response = await api.get("/Autenticacao/ValidaToken")

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    return error.response?.data
  }
}

export const TakeVacancy = async (
  deCode: string,
  vacancyNumber: string,
  cpf: string,
  employeeName: string,
  email: string
) => {
  try {
    let response = await api.put("/Vaga/AssumirVaga", {
      cpf: cpf,
      idExterno: deCode,
      desativaValidacao: false,
      nrChamado: vacancyNumber,
      nomeEmpregado: employeeName,
      email: email,
    })

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    return error.response?.data
  }
}

export const DeleteVacancy = async (vacancyNumber: string) => {
  try {
    let response = await api.put("/Vaga/CancelarVagaOnboarding", {
      nrChamado: vacancyNumber,
    })

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    return error.response?.data
  }
}

export const ClearVacancy = async (vacancyNumber: string) => {
  try {
    let response = await api.put("/Vaga/ApagarDadosVaga", {
      nrChamado: vacancyNumber,
    })

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    return error.response?.data
  }
}

export const DeleteDocument = async (documentId: number) => {
  try {
    let response = await api.delete(`Documento/DeleteDocumento?id=${documentId}`)

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    return error.response?.data
  }
}

export const GetSections = async (deCode: string) => {
  try {
    let response = await api.get<ISections[]>(`/Vaga/ListarSecoes?login=${deCode}`)

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    return error.response?.data
  }
}

export const GetVacanciesBySections = async (deCode: string, sectionsDescription: string) => {
  try {
    let response = await api.get<IVacanciesBySection>(
      `Vaga/ListarVagas?login=${deCode}&descSecao=${sectionsDescription}`
    )

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    return error.response?.data
  }
}

export const GetVacancyDetails = async (vacancyNumber: string) => {
  try {
    let response = await api.get(`/Vaga/DetalhesVaga?nrChamado=${vacancyNumber}`)

    if (response?.status >= 200 && response?.status < 300) return response.data
  } catch (error: any) {
    return error.response?.data
  }
}

export const GetVacancyDocuments = async (vacancyNumber: string) => {
  try {
    let response = await api.get<IDocuments>(
      `/Documento/DocumentosOnboarding?nrChamado=${vacancyNumber}`
    )

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    return error.response?.data
  }
}

export const GetDocumentsQuantityByVacancy = async (vacanciesNumbers: string) => {
  try {
    let response = await api.get(
      `/Documento/QtdeDocumentoByVagas?listaNrChamado=${vacanciesNumbers}`
    )

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    return error.response?.data
  }
}

export const SendToCentral = async (vacancyNumber: string) => {
  try {
    let response = await api.post("/Documento/EnviaDocumentosFluig", {
      nrChamado: vacancyNumber,
    })

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    return error.response?.data
  }
}

export const UploadDocument = async (images: Array<string | Blob>, documentGuid: string | Blob) => {
  let formData = new FormData()

  //@ts-ignore
  formData.append("documentoListaGuid", documentGuid)

  const verifyExtension = (file: string | Blob) => {
    if (typeof file === "string") {
      formData.append("documentosAnexados", dataToBlob(file), "imagem.pdf")
    } else {
      formData.append("documentosAnexados", file, "imagem.jpg")
    }
  }

  images.forEach(image => {
    verifyExtension(image)
  })

  try {
    let response = await api.post(`Documento/PostDocumento`, formData)

    if (response.data) return response
  } catch (error: any) {
    return error.response?.data
  }
}

export const receiveTermsLink = async () => {
  try {
    let response = await api.get("/Termo")

    if (response?.status >= 200 && response?.status < 300) return response
  } catch (error: any) {
    return error.response?.data
  }
}


export const verifyLink = async (key: string) => {

  let response = await api.get<{email: string, nome: string, telefone: string, nrChamado: string, dataExpiracao: string,}>("/LinkVaga/VerifyLink?key="+key)
  return response.data

}

export const vacancyLinkLogin = async (keyLink: string, keyLogin: string) => {

  try
  {
    let response = await api.post<{session: string | null}>("/Autenticacao/LoginLinkVaga", 
    {
      keyLogin,
      keyLink
    })
    return response.data.session
  } catch (err) {
    return null
  }

}

export const getEmployeeData = async (employeeCPF: string) => {
  try {
    const response = await api.get(`/LinkVaga/GetDadosEmpregado?cpf=${employeeCPF}`);
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (error: any) {
    return error.response?.data;
  }
};


export const sendEmployeeData = async (data: Partial<Ficha>) => {
  try {
    const response = await api.post(
      "/LinkVaga/EnviaDadosEmpregado",
      JSON.stringify(data, null, 0),
      {
        headers: {
          'Content-Type': 'application/json',
          'accept': 'text/plain',
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (error: any) {
    console.error('Erro ao enviar dados:', error);
    return error.response?.data;
  }
};

export const deleteValeTransporte = async (cpf: string, linha: string) => {
  try {
    const response = await api.delete(`/LinkVaga/ExcluirValeTransporte?cpf=${cpf}&linha=${linha}`, {
      headers: {
        'accept': 'text/plain',
      },
    });
    return response;
  } catch (error: any) {
    console.error('Erro ao excluir vale transporte:', error);
    return { status: error.response?.status, data: error.response?.data };
  }
};

export const deleteDependente = async (cpf: string) => {
  try {
    const response = await api.delete(`/LinkVaga/ExcluirDependente?cpf=${cpf}`, {
      headers: {
        'accept': 'text/plain',
      },
    });
    return response;
  } catch (error: any) {
    console.error('Erro ao excluir dependente:', error);
    return { status: error.response?.status, data: error.response?.data };
  }
};

export const getEstadoNatal = async () => {
  try {
    const cachedEstados = localStorage.getItem("estados");
    if (cachedEstados) {
      return JSON.parse(cachedEstados);
    }

    const response = await api.get("/LinkVaga/GetEstadoNatal");
    if (response.status >= 200 && response.status < 300) {
      localStorage.setItem("estados", JSON.stringify(response.data));
      return response.data;
    }
  } catch (error: any) {
    return error.response?.data;
  }
};

export const getCidadeNatal = async (siglaEstado: string) => {
  try {
    const cachedCidades = localStorage.getItem(`cidades_${siglaEstado}`);
    if (cachedCidades) {
      return JSON.parse(cachedCidades);
    }

    const response = await api.get(`/LinkVaga/GetCidadeNatal?siglaEstado=${siglaEstado}`);
    if (response.status >= 200 && response.status < 300) {
      localStorage.setItem(`cidades_${siglaEstado}`, JSON.stringify(response.data));
      return response.data;
    }
  } catch (error: any) {
    return error.response?.data;
  }
};


export const generateSensediaToken = async (keyLink: string, code: string, session: string | null) => {

  const body = session === null ? {keyLink, code} : {keyLink, code, session}
  let response = await api.post<{access_token: string}>("/Autenticacao/GenerateTokenSensedia", body)
  return response.data.access_token;

}

export default api
