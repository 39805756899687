import { useNavigate } from "react-router-dom"

import { createContext, useState, ReactNode, useEffect } from "react"

import { TokenValidation } from "../services/api"

import { isAuthenticated } from "../services/auth"

type User = {
  email: string | null
  lastName: string | null
  password?: string | null
  firstName: string | null
  externalId: string | null
}

type Images = {
  imagesArray: Array<string | Blob | File>
}

type AuthTypes = {
  user: User
  images: Images
  setUser: (user: User) => void
  setImages: (images: Images) => void
}

type AuthTypesProps = {
  children: ReactNode
}

export const AuthContext = createContext({} as AuthTypes)

const AuthProvider = (props: AuthTypesProps) => {
  const navigate = useNavigate()

  var firstName = localStorage.getItem("firstName")

  const [user, setUser] = useState<User>({} as User)

  const [images, setImages] = useState<Images>({ imagesArray: [] })

  useEffect(() => {
    setUser({
      email: localStorage.getItem("email"),
      lastName: localStorage.getItem("lastName"),
      firstName: localStorage.getItem("firstName"),
      externalId: localStorage.getItem("externalId"),
    })
  }, [firstName])

  const handleTokenValidation = async () => {
    let response = await TokenValidation()

    if (response?.status >= 200 && response.data < 300) {
      if (window.location.pathname === "/") {
        navigate("/auth")
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated()) {
      handleTokenValidation()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        images,
        setUser,
        setImages,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
