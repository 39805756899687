import React from 'react';
import { Dependente } from '../typesFicha';
import { Typography, Grid } from '@mui/material';
import { ChevronRight, Person2Outlined } from '@mui/icons-material';

interface DependenteCardProps {
  dependente: Dependente;
  onEdit: () => void;
}


const DependenteCard: React.FC<DependenteCardProps> = ({ dependente, onEdit }) => {

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onEdit();
    }
  };

  return (
    <div className="card"
      onClick={onEdit}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}>
      <Grid item className="icon">
        <Person2Outlined style={{ color: '#1d3054', fontSize: 24, width: 40, height: 40, borderRadius: 4, padding: 8 }} />
      </Grid>
      <div className="cardClickable">
        <div className="textContainer">
          <Typography variant="h6" className='cardTitle'>
            {dependente.nome}
          </Typography>
          <Typography variant="body1" className='cardSubtitle'>
            {dependente.grauParentesco}
          </Typography>
        </div>
        <div className="chevronContainer">
          <ChevronRight style={{ color: '#1d3054' }} />
        </div>
      </div>
    </div>
  );
};

export default DependenteCard;
