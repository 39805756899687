import { ReactNode } from "react"

import Button, { ButtonProps } from "@mui/material/Button"

import { makeStyles } from "@mui/styles"

import { useTheme } from "@mui/material/styles"

export interface MainButtonProps extends ButtonProps {
  children: ReactNode | string
  noPadding?: boolean
  buttonStyle?: "white" | "outlined" | "outlinedLight" | "error" | "errorOutlined" | "contained"
  onClick?: () => Promise <void> | void
}

export default function MainButton({
  children,
  noPadding,
  buttonStyle = "contained",
  ...props
}: MainButtonProps) {
  const theme = useTheme()

  const color = () => {
    if (buttonStyle === "outlined" || buttonStyle === "outlinedLight" || buttonStyle === "white")
      return theme.palette.primary.main

    if (buttonStyle === "errorOutlined") return theme.palette.secondary.main

    return "#FFF"
  }

  const background = () => {
    if (
      buttonStyle === "white" ||
      buttonStyle === "outlined" ||
      buttonStyle === "outlinedLight" ||
      buttonStyle === "errorOutlined"
    )
      return "#FFF"

    if (buttonStyle === "error") return theme.palette.secondary.main

    return theme.palette.primary.main
  }

  const borderColor = () => {
    if (buttonStyle === "outlined") return theme.palette.primary.main
    else if (buttonStyle === "outlinedLight") return theme.palette.primary.light
    else if (buttonStyle === "error" || buttonStyle === "errorOutlined")
      return theme.palette.secondary.main

    return theme.palette.primary.main
  }

  const useStyles = makeStyles({
    button: {
      color: color(),

      border: "solid 2px",

      padding: noPadding ? 0 : "6px 16px",

      background: background(),

      borderColor: borderColor(),

      "&:hover": {
        background: background(),
      },

      "&:disabled": {
        color: "#FFF",
        background: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },

      fontWeight: 700,

      textTransform: "none",
    },
  })

  const classes = useStyles()

  return (
    <Button className={classes.button} {...props}>
      {children}
    </Button>
  )
}
