import { ReactNode } from "react"
import MainButton from "./MainButton"

type ConfirmButtonProps = {
    children: ReactNode | string
    onClick: () => void,
    disabled?: boolean
}

const OutlineButton = ({children, onClick, disabled}: ConfirmButtonProps) => {

    return (
        <MainButton
        size='large'
        disabled={disabled}
        fullWidth={true}
        style={{ 
          backgroundColor: "#fff",
          color: disabled ? '#848484' : '#003da5',
          padding: 16,
          fontWeight: 700,
          fontSize: 16,
          lineHeight: "20px",
          marginTop: 8,
          borderRadius: 8,
          borderColor: disabled ? '#848484' : '#003da5',
          borderWidth: 2
        }}
        buttonStyle='contained'
        onClick={onClick}
      >
        {children}
      </MainButton>
    )

}

export default OutlineButton