// ConfirmVacanciesLink.tsx
import { Paper, Grid, Typography } from "@mui/material"
import { FiAlertCircle } from "react-icons/fi"
import { makeStyles } from "@mui/styles"
import MainButton from "./MainButton"
import BackdropLoading from "./BackdropLoading"
import { swalAlert } from "../helpers/swalAlert"
import { useState } from "react";

interface ConfirmVacanciesLinkProps {
  open: boolean;
  close: () => void;
  onConfirm: () => void;
}

export default function ConfirmVacanciesLink({ open, close, onConfirm }: ConfirmVacanciesLinkProps) {
  const [waitingResponse, setWaitingResponse] = useState(false);

  const useStyles = makeStyles(theme => ({
    modal: {
      right: 0,
      top: "20%",
      left: "50%",
      zIndex: 99,
      width: "80%",
      maxWidth: 600,
      position: "fixed",
      height: open ? 400 : 0,
      maxHeight: 600,
      borderRadius: 32,
      transition: "height 0.1s",
      transform: "translateX(-50%)",
      display: open ? "flex" : "none",
    },
    modalTitle: {
      fontWeight: 700,
      fontSize: 18,
      color: "#A16207",
    },
    buttonsContainer2: {
      padding: 16,
      width: "100%",
      maxWidth: 600,
    },
  }));

  const classes = useStyles();

  const handleConfirm = async () => {
    setWaitingResponse(true);
    // Pode adicionar lógica adicional aqui antes de chamar onConfirm
    await onConfirm();
    setWaitingResponse(false);
    close();
  };

  return (
    <>
      <Paper elevation={20} className={classes.modal}>
        <Grid container style={{ padding: 16, justifyContent: "center" }}>
          <FiAlertCircle
            size={48}
            color='#A16207'
            style={{ marginBottom: 8 }}
          />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant='h6' color='primary' className={classes.modalTitle} align='center'>
              {"Link de documentos já enviado"}
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            alignItems='center'
            style={{ marginTop: 8, marginBottom: 8, width: "100%", justifyContent: "center" }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
              style={{ textAlign: "center" }}>
              <Typography
                variant='caption'
                align='center'
                fontSize={14}
              >
                Já foi enviado um link de documentos para o candidato. Deseja assumir a vaga mesmo assim?
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction='column'
            alignItems='center'
            className={classes.buttonsContainer2}
          >
            <MainButton
              size='medium'
              fullWidth={true}
              buttonStyle='contained'
              onClick={handleConfirm}
              style={{
                marginBottom: 8,
                maxWidth: 400,
                textTransform: "uppercase",
                backgroundColor: "#1D3054",
                color: "#FFF",
                border: "2px solid #1D3054",
                borderRadius: 8,
                padding: 8,
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Assumir vaga
            </MainButton>

            <MainButton
              size='medium'
              onClick={close}
              fullWidth={true}
              buttonStyle='error'
              style={{
                maxWidth: 400,
                textTransform: "uppercase",
                color: "#1D3054",
                border: "2px solid #1D3054",
                backgroundColor: "#FFF",
                borderRadius: 8,
                padding: 8,
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Cancelar
            </MainButton>
          </Grid>
        </Grid>
      </Paper>

      <BackdropLoading open={waitingResponse} />
    </>
  );
}
