import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import { MdCancel } from "react-icons/md";
import Steps from "./Steps";
import useAuth from "../../../hooks/useAuth";
import MainTabs from "../../../components/MainTabs";
import { IDocuments } from "../../../@types/Documents";
import MobileContainer from "../../../components/MobileContainer";
import useIsLinkRoute from "../../../hooks/useIsLinkRoute";

export default function UploadDocument() {
  const navigate = useNavigate();
  const { images, setImages } = useAuth();
  const [documentToUpload, setDocumentToUpload] = useState<IDocuments>({} as IDocuments);
  const isLinkRoute = useIsLinkRoute();

  useEffect(() => {
    setDocumentToUpload(JSON.parse(localStorage.getItem("documentToUpload") as string));
  }, []);

  const clearImagesArray = () => {
    setImages({ imagesArray: [] });
  };

  const labelForMultiplePages = () => {
    if (images?.imagesArray?.length === 0)
      return `Revisar documento 1 de ${documentToUpload?.numeroPaginas}`;

    return `Revisar documento ${String(images?.imagesArray?.length)} de ${documentToUpload?.numeroPaginas}`;
  }

  const tabList = () => {
    return [{ value: "1", label: labelForMultiplePages(), cursor: "initial", component: <Steps multiplePages /> }];
  }

  return (
    <MobileContainer blueBackground style={{ overflowY: "auto", paddingBottom: 20 }}>
      <Grid container alignItems='center' style={{ marginTop: 8, marginBottom: 8 }}>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
          <Typography variant='body2' style={{ color: "#FFF", textAlign: "center", marginLeft: "20%", fontSize: "16px", fontWeight: "700" }}>
            Envie o documento
          </Typography>
        </Grid>

        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} container justifyContent='flex-end'>
          <MdCancel size={30} cursor={"pointer"} color='#BFD7FF' onClick={() => {
            clearImagesArray();
            const targetRoute = isLinkRoute ? "/link/documentos/anexar" : "/auth/documentos/anexar";
            navigate(targetRoute);
          }} />
        </Grid>

      </Grid>

      <MainTabs white tabList={tabList()} marginTop={"0%"} />
    </MobileContainer>
  )
}
