import { makeStyles } from "@mui/styles"

import { CircularProgress } from "@mui/material"

import LinkMobileContainer from "./LinkMobileContainer"
import LogoHeader from "./LogoHeader"


function LoadingInterface() {

  const useStyles = makeStyles(theme => ({

    content: {
      height: 600,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 24
    }

  }))

  const classes = useStyles()

  return (
    <LinkMobileContainer>
      <LogoHeader/>
      <div className={classes.content}>
        <CircularProgress/>
      </div>
    </LinkMobileContainer>
  )
}

export default LoadingInterface
