import { ChangeEvent, ReactNode } from "react"

import { Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"

import Swal from "sweetalert2"
import Compressor from "compressorjs"

import useAuth from "../hooks/useAuth"
import { dataToBlob } from "./format"

interface AccessDeviceProps {
  id?: string
  camera?: boolean
  outlined?: boolean
  link?: boolean
  buttonText: string
  buttonIcon?: ReactNode
  setFile: (image: string | Blob) => void
}

export function AccessDevice({
  id,
  camera,
  setFile,
  outlined,
  buttonText,
  buttonIcon,
  link,
}: AccessDeviceProps) {
  const { images, setImages } = useAuth()

  const useStyles = makeStyles((theme: Theme) => ({
    button: {
      height: 40,
      width: "80%",
      fontSize: 18,
      borderRadius: 8,
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      textTransform: "none",
      justifyContent: "center",
      border: outlined ? "2px solid" : "none",
      color: outlined ? theme.palette.secondary.main : "#FFF",
      borderColor: outlined ? theme.palette.secondary.main : "transparent",
      background: outlined ? "transparent" : theme.palette.secondary.main,
    },
    linkButton: {
      height: 56,
      width: "100%",
      fontSize: 18,
      fontWeight: 700,
      borderRadius: 8,
      paddingTop: 16,
      paddingBottom: 16,
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      textTransform: "none",
      justifyContent: "center",
      border: "none",
      color: "#003DA5",
      borderColor: "#FFF",
      background: "#FFF",
    }
  }))

  const classes = useStyles()

  const verifyValidExtension = (file: string) => {
    if (
      file.includes("image/jpeg") ||
      file.includes("image/jpg") ||
      file.includes("image/png") ||
      file.includes("application/pdf")
    ) {
      return true
    } else {
      Swal.fire({
        allowOutsideClick: false,
        title:
          "<h5 style='color: #A93335; margin-top: 8px; margin-bottom: 0px'>Formato inválido</h5>",
        confirmButtonColor: "#29333C",
        confirmButtonText: "        TENTAR NOVAMENTE        ",
        html: "<strong>O documento precisa estar no formato jpg, png ou pdf.</strong> <p>Por favor, tente novamente.</p> <hr/>",
      })
    }
  }

  const verifyFileSize = (file: Blob) => {
    let fileSizeInMb = file.size / 1024 / 1024

    if (fileSizeInMb <= 20) {
      return true
    } else {
      Swal.fire({
        allowOutsideClick: false,
        title:
          "<h5 style='color: #A93335; margin-top: 8px; margin-bottom: 0px'>Tamanho inválido</h5>",
        confirmButtonColor: "#29333C",
        confirmButtonText: "        TENTAR NOVAMENTE        ",
        html: "<strong>O documento tem mais de 20mb e ultrapassa nosso limite de envio.</strong> <p>Por favor, tente novamente.</p> <hr/>",
      })
    }
  }

  const insertImageIntoArray = (
    event: ChangeEvent<HTMLInputElement>,
    image: string | Blob | File
  ) => {
    if (event.target?.id === "tryAgain") {
      images?.imagesArray?.pop()
      setImages({ imagesArray: images?.imagesArray })
    }

    images?.imagesArray?.push(image)

    setImages({ imagesArray: images?.imagesArray })
  }

  const compressImage = (image: string, event: ChangeEvent<HTMLInputElement>) => {
    if (image?.includes("application/pdf")) {
      if (verifyFileSize(dataToBlob(image))) {
        setFile(image)
        insertImageIntoArray(event, image)
      }
    } else {
      new Compressor(dataToBlob(image), {
        quality: 0.8,
        success: compressedResult => {
          if (verifyFileSize(compressedResult)) {
            setFile(image)
            insertImageIntoArray(event, compressedResult)
          }
        },
      })
    }
  }

  const handleFile = (event: any) => {
    const reader: any = new FileReader()

    reader.onload = async () => {
      if (reader.readyState === 2) {
        if (verifyValidExtension(reader.result)) {
          compressImage(reader.result, event)
        }
      }
    }

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0])
    }
  }

  return (
    <>
      <label htmlFor={id} className={`${classes.button} ${link ? classes.linkButton : ""}`}>
        <input
          id={id}
          type='file'
          onChange={handleFile}
          style={{ display: "none" }}
          accept='.jpg, .jpeg, .png, .pdf'
          capture={camera ? "environment" : false}
        />

        {buttonIcon}

        <p>{buttonText}</p>
      </label>
    </>
  )
}

export const share = (url: string) => {
  if (navigator.share) {
    navigator
      .share({
        text: `${url}`,
        title: "Direcional Engenharia",
      })
      .then(() => console.log("Compartilhamento ok"))
      .catch(error => console.log("Erro: ", error))
  }
}

export const contactWhatsApp = (message: string) => {
  window.open(`https://api.whatsapp.com/send?phone=&text=${message}`)
}
