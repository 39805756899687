export const calculateAge = (birthDate: string | null): number => {
  if (!birthDate) return 0;
  const dtNascimento = new Date(birthDate);
  const age = new Date().getFullYear() - dtNascimento.getFullYear();
  const monthDiff = new Date().getMonth() - dtNascimento.getMonth();
  const dayDiff = new Date().getDate() - dtNascimento.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    return age - 1;
  }
  return age;
};

export const verificaIdentidade = (nacionalidade: string | null) => {
  if (nacionalidade === null || (nacionalidade && nacionalidade.toLowerCase() !== 'brasileira')) {
    return false;
  }
  return true;
}

export const verificaEleitor = (dtNascimento: string | null, nacionalidade: string | null) => {
  if ((calculateAge(dtNascimento) < 18 || calculateAge(dtNascimento) > 70) || (nacionalidade === null || (nacionalidade && nacionalidade.toLowerCase() !== 'brasileira'))) {
    return false;
  }
  return true;
}

export const verificaReservista = (sexoColaborador: string | null, dtNascimento: string | null, nacionalidade: string | null) => {
  if ((calculateAge(dtNascimento) < 18 || calculateAge(dtNascimento) > 70) || (nacionalidade === null || (nacionalidade && nacionalidade.toLowerCase() !== 'brasileira'))) {
    return false;
  }
  if ((sexoColaborador !== 'M')) {
    return false;
  }
  return true;
}