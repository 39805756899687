import { useState, useEffect, ChangeEvent } from "react"

import { useNavigate } from "react-router-dom"

import { useFormik } from "formik"

import { makeStyles } from "@mui/styles"

import { Grid, Typography, TextField, FormControlLabel, Checkbox, Theme } from "@mui/material"

import useAuth from "../../hooks/useAuth"
import useAlert from "../../hooks/useAlert"
import useCheckbox from "../../hooks/useCheckbox"
import { login, logout } from "../../services/auth"
import { swalAlert } from "../../helpers/swalAlert"
import MainButton from "../../components/MainButton"
import BackdropLoading from "../../components/BackdropLoading"
import MobileContainer from "../../components/MobileContainer"
import { UserValidate, UserLogin, GetSections, receiveTermsLink } from "../../services/api"
import { datadogRum } from '@datadog/browser-rum';

export default function VerificationCode() {
  const navigate = useNavigate()

  const { setUser } = useAuth()

  const { alert, setAlert } = useAlert()

  const [counter, setCounter] = useState<number>(60)

  const { checkbox, setCheckbox } = useCheckbox()

  const [background, setBackground] = useState<"gray" | "blue">("blue")

  const [serviceTerms, setServiceTerms] = useState<string>()

  const [privacyPolicy, setPrivacyPolicy] = useState<string>()

  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      top: 0,
      right: 0,
      margin: 0,
      padding: 0,
      zIndex: -1,
      left: "50%",
      maxWidth: 444,
      width: "101%",
      height: "101%",
      transition: "1s",
      maxHeight: "101%",
      position: "fixed",
      objectFit: "none",
      transform: "translateX(-50%)",
      filter: background === "gray" ? "grayscale(100%) contrast(50%)" : "none",
    },
    logoGrid: {
      display: "flex",
      justifyContent: "center",
      marginBottom: 16,
      borderRadius: "16px 16px 0 0",
    },
    logo: {
      width: 260,
      marginTop: 8,
      marginBottom: 8,
    },
  }))

  const classes = useStyles()

  const handleUnderstandAndAgree = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckbox({ ...checkbox, understandAndAgree: event.target.checked })
  }

  const resendCodeButtonText = () => {
    if (counter === 0) return "REENVIAR CÓDIGO"

    return `REENVIAR CÓDIGO EM ${counter}s`
  }

  useEffect(() => {
    if (localStorage.getItem("isTermsSigned") === "true") {
      setCheckbox({
        understandAndAgree: true,
      })
    }

    if (!counter) return

    const interval = setInterval(() => {
      setCounter(counter - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [counter])

  const resendCode = async () => {
    setCounter(60)

    setAlert({ ...alert, waitingResponse: true })

    const userId = localStorage.getItem("tempIdUsuario") as string

    const password = localStorage.getItem("tempSenha") as string

    const tokenCloud = localStorage.getItem("tempT") as string

    let response = await UserLogin(userId, password, tokenCloud)

    if (response?.status >= 200 && response?.status < 300) {
      setAlert({ ...alert, waitingResponse: false })

      swalAlert("success", "Outro código foi enviado para o seu e-mail", "OK, ENTENDI")
    } else {
      setAlert({ ...alert, waitingResponse: false })

      swalAlert("error", response, "TENTAR NOVAMENTE")
    }
  }

  const getTermsLink = async () => {

    setAlert({ ...alert, waitingResponse: true })

    let response = await receiveTermsLink()

    setServiceTerms(response.data?.[0].url)
    setPrivacyPolicy(response.data?.[1].url)

    if (response?.status >= 200 && response?.status < 300) {
      setAlert({ ...alert, waitingResponse: false })
    } else {
      setAlert({ ...alert, waitingResponse: false })

      swalAlert("error", response, "TENTAR NOVAMENTE")
    }
  }

  const { getFieldProps, handleSubmit, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: "",
    },
    onSubmit: async submit => {
      const userId = localStorage.getItem("tempIdUsuario") as string
      const password = localStorage.getItem("tempSenha") as string

      setAlert({ ...alert, waitingResponse: true })

      let response = await UserValidate(userId, password, submit.code)

      if (response?.status >= 200 && response?.status < 300) {
        login(response.data.tokenSensedia.access_token)

        localStorage.setItem("email", response.data.usuario.email)
        localStorage.setItem("lastName", response.data.usuario.ultimoNome)
        localStorage.setItem("externalId", response.data.usuario.IdExterno)
        localStorage.setItem("firstName", response.data.usuario.primeiroNome)

        setUser({
          email: response.data.usuario.email,
          lastName: response.data.usuario.ultimoNome,
          externalId: response.data.usuario.IdExterno,
          firstName: response.data.usuario.primeiroNome,
        })

        datadogRum.setUser({
          id: response.data.usuario.IdExterno,
          name: response.data.usuario.primeiroNome,
          email: response.data.usuario.email,
          username: response.data.usuario.email,
        });

        let sectionsResponse = await GetSections(response.data.usuario.IdExterno)

        if (sectionsResponse?.status >= 200 && sectionsResponse?.status < 300) {
          localStorage.setItem("sectionCenters", JSON.stringify(sectionsResponse.data))

          localStorage.removeItem("tempSenha")
          localStorage.removeItem("hiddenEmail")
          localStorage.removeItem("tempIdUsuario")

          navigate("/auth")
        } else {
          logout()
          swalAlert("error", sectionsResponse, "TENTAR NOVAMENTE")
        }
      } else {
        swalAlert("error", response, "TENTAR NOVAMENTE")
      }
      setAlert({ ...alert, waitingResponse: false })
    },
  })

  useEffect(() => {
    (async function loadDocs() { await getTermsLink() }())
  }, [])

  useEffect(() => {
    if (values.code.length === 6 && checkbox.understandAndAgree) {
      setBackground("gray")
    } else {
      setBackground("blue")
    }
  }, [values.code, checkbox.understandAndAgree])

  const disableButton = () => {
    if (values.code.length !== 6 && checkbox.understandAndAgree) {
      return true
    } else if (!checkbox.understandAndAgree) {
      return true
    } else {
      return false
    }
  }

  const hiddenEmail = localStorage.getItem('hiddenEmail');

  const emailEncontrado = hiddenEmail !== '';

  return (
    <>
      <MobileContainer style={{ backgroundColor: "#ffffff", padding: 0 }}>
        <Grid justifyContent="center" className={classes.logoGrid} style={{ marginLeft: "auto", marginRight: "auto", width: "100%", backgroundColor: "#1D3054" }}>
          <img alt='Logo branco' src='/assets/images/logo_grupo.png' className={classes.logo} />
        </Grid>
        <div
          style={{ marginLeft: 16, marginRight: 16 }}>
          <Typography style={{ marginBottom: 16, marginTop: 16, color: "#1D3054", fontSize: 24, fontWeight: 700 }}>
            <strong>Validar acesso</strong>
          </Typography>

          {emailEncontrado ? (
            <>
              <Typography style={{ marginBottom: 8, color: "#1D3054" }}>
                Enviamos um código para o e-mail:
              </Typography>

              <Typography color="initial" style={{ marginBottom: '16px', color: '#1D3054', fontWeight: 700 }}>
                {hiddenEmail}.
              </Typography>
            </>
          ) : (
            <Typography color="initial" style={{ color: '#1D3054' }}>
              Enviamos um código para o e-mail cadastrado.
            </Typography>
          )}

          <form
            onSubmit={handleSubmit}>
            <TextField
              type='tel'
              fullWidth
              variant='standard'
              {...getFieldProps("code")}
              autoComplete='one-time-code'
              inputProps={{
                maxLength: 6,
                style: {
                  fontSize: 36,
                  color: "#4D4D4D",
                  fontWeight: 700,
                  letterSpacing: 32,
                  textAlign: "center",
                },
              }}
            />

            <Grid container alignItems='center' style={{ marginTop: 16, marginBottom: 24 }}>
              <MainButton
                size='large'
                fullWidth={true}
                disabled={counter !== 0}
                onClick={() => resendCode()}
                style={{ padding: 16 }}
              >
                {resendCodeButtonText()}
              </MainButton>
            </Grid>

            {localStorage.getItem("isTermsSigned") === "true" ?
              <></>
              :
              <Grid container>
                <Typography variant='caption' style={{ color: "#4D4D4D" }}>
                  Ao continuar, você concorda com os &nbsp;
                  <a
                    target='_blank'
                    id='termsOfService'
                    href={serviceTerms}
                    style={{ fontWeight: 700, color: "#4D4D4D" }}
                    rel="noreferrer"
                  >
                    Termos de Serviço
                  </a>{" "}
                  e confirma que leu a &nbsp;
                  <a
                    target='_blank'
                    id='privacyPolicy'
                    href={privacyPolicy}
                    style={{ fontWeight: 700, color: "#4D4D4D" }}
                    rel="noreferrer"
                  >
                    Política de Privacidade
                  </a>
                  .
                </Typography>
                <FormControlLabel
                  id='understandAndAgree'
                  label='Entendi e concordo'
                  style={{ marginTop: 16, color: "#4D4D4D" }}
                  control={<Checkbox style={{ color: "#4D4D4D" }}
                    onChange={handleUnderstandAndAgree} />}
                />
              </Grid>}

            <MainButton
              size='large'
              type='submit'
              fullWidth={true}
              disabled={disableButton()}
              style={{ marginTop: 8, padding: 16 }}
            >
              AVANÇAR
            </MainButton>
          </form>

          <BackdropLoading open={alert?.waitingResponse} />
        </div>
      </MobileContainer>
    </>
  )
}
