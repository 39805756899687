import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FiArrowLeft } from "react-icons/fi";
import { ExitToApp, HomeOutlined, ReplayOutlined } from "@mui/icons-material";
import useAlert from "../hooks/useAlert";
import { logout } from "../services/auth";
import useVacancies from "../hooks/useVacancies";
import { GetSections } from "../services/api";
import { requestVacanciesBySections } from "../services/getVacancies";

interface HeaderProps {
  backArrow?: boolean;
  path?: string;
  transparent?: boolean;
}

interface IReplayOutlined {
  onClick?: () => Promise<void> | void;
}

export default function Header({ backArrow, path, transparent }: HeaderProps) {
  const { setVacancies } = useVacancies();
  const { alert, setAlert } = useAlert();
  const navigate = useNavigate();

  const useStyles = makeStyles({
    container: {
      marginBottom: 8,
      backgroundColor: transparent ? "#ffffff0" : "#1C2F54",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: 16,
    },
    logoImage: {
      width: 160,
    },
    title: {
      color: transparent ? "#FFF" : "#1C2F54",
    },
    iconsColor: {
      marginTop: 4,
      color: "#fff",
      cursor: "pointer",
      height: 24,
      marginLeft: 12,
    },
  });

  const classes = useStyles();

  const exitApp = () => {
    logout();
    navigate("/");
  };

  const refreshCostCenters = async () => {
    setAlert({ ...alert, waitingResponse: true });

    localStorage.setItem("slide", "0");

    let costCentersResponse = await GetSections(localStorage.getItem("externalId") as string);

    if (costCentersResponse?.status >= 200 && costCentersResponse?.status < 300) {
      localStorage.setItem("sectionCenters", JSON.stringify(costCentersResponse.data));

      let vacanciesResponse = await requestVacanciesBySections();

      setVacancies(vacanciesResponse);

      setAlert({ ...alert, waitingResponse: false });
    } else {
      setAlert({ ...alert, waitingResponse: false });
    }
  };

  const returnToHome = () => {
    navigate("/auth");
    localStorage.setItem("slide", "0");
  };

  const backToPath = (path: string) => {
    navigate(path);
    localStorage.setItem("slide", "0");
  };

  return (
    <Grid container className={classes.container}>
      {backArrow && path && (
        <Grid item>
          <FiArrowLeft size={32} color="#ffffff" style={{ marginTop: 8 }} onClick={() => backToPath(path)} />
        </Grid>
      )}

      <Grid item>
        <img src="/assets/images/logo_grupo.png" alt="Logo" className={classes.logoImage} />
      </Grid>

      <Grid item>
        <HomeOutlined fontSize="large" className={classes.iconsColor} onClick={returnToHome} />

        <ReplayOutlined fontSize="large" onClick={() => refreshCostCenters() as IReplayOutlined} className={classes.iconsColor} />

        <ExitToApp fontSize="large" className={classes.iconsColor} onClick={exitApp} />
      </Grid>
    </Grid>
  );
}
