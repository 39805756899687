import { makeStyles } from "@mui/styles"

import { Typography, Container } from "@mui/material"


function ErrorInterface() {

  const useStyles = makeStyles(theme => ({

    content: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh'
    },

    errorImage: {
      width: 307,
      height: 307,
      marginBottom: 32
    },

    errorTitle: {
      fontSize: 36,
      lineHeight: "44px",
      fontWeight: 700,
      color: '#003da5',
      textAlign: 'center',
      marginBottom: 32
    },

    errorText: {
        fontWeight: 400,
        fontSize: 18,
        lineHeight: "28px",
        color: "#4d4d4d",
        textAlign: 'center'
    }

  }))

  const classes = useStyles()

  return (
    <Container maxWidth="xs">
      <div className={classes.content}>
        <img alt='Logo branco' src='/assets/images/link_invalido.png' className={classes.errorImage} />
        <Typography variant="body2" className={classes.errorTitle}>
          Link inválido
        </Typography>
        <Typography variant="body2" className={classes.errorText}>
          Este link expirou, caso tenha dúvidas, entre em contato com o RH do Grupo Direcional.
        </Typography>
      </div>
    </Container>
  )
}

export default ErrorInterface
