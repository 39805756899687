import React, { useState, useEffect, ReactNode, useRef, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography, Grid, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { sendEmployeeData } from '../../../services/api';
import './FichaCadastral.css';
import TitleVacancyDetails from '../../../components/TitleVacancyDetails';
import CardFichaCadastral from '../../../components/CardFichaCadastral';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Pagination from '../../../components/Pagination';
import ConfirmButton from '../../../components/ConfirmButton';
import { FichaContext } from '../../../context/FichaContext';
import { verificaEleitor, verificaIdentidade, verificaReservista } from '../../../helpers/validationFicha';
import { checkDadosPessoais, checkCarteiraDeTrabalho, checkCertificadoReservista, checkDadosBancarios, checkDocumentoDeIdentidade, checkEndereco, checkEstadoCivil, checkEtnia, checkGeneroOrientacao, checkGrauInstrucao, checkInformacoesContato, checkPessoaComDeficiencia, checkTituloDeEleitor, checkValeTransporte, checkDependentes, checkVaVr } from './checkFieldsFilles';
import useDocumentLink from '../../../hooks/useDocumentLink';
import { swalAlert } from '../../../helpers/swalAlert';
import GoBackHeader from '../../../components/GoBackHeader';
import useFetchEmployeeData from './useFetchEmployeeData';
import { useFetchVacancyDetail } from './useFetchVacancyDetail';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: '100vh',
    padding: 0,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 67,
  },
  titleContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginBottom: 24,
  },
  title: {
    fontSize: 20,
    lineHeight: "30px",
    fontWeight: 600,
    color: '#1d3054'
  },
  description: {
    fontSize: 14,
    lineHeight: "20px",
    color: '#4d4d4d'
  },
  sliderContainer: {
    maxWidth: 412,
    width: '91vw',
    marginTop: 16,
    marginBottom: 16,
  },
}));

const FichaCadastral = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [pageFicha, setPageFicha] = useState<any>(0);
  const [swiper, setSwiper] = useState<any>(null);
  const elementRef = useRef<any>(null);
  const [height, setHeight] = useState(0);
  const [vacancyDetails, setVacancyDetails] = useState<any>({});
  const fichaContext = useContext(FichaContext);
  const { user } = useDocumentLink();
  const nrChamado = user.nrChamado || sessionStorage.getItem("nrChamadoLink");
  const [loading, setLoading] = useState(true);

  useFetchVacancyDetail(nrChamado, setVacancyDetails, vacancyDetails, setLoading, loading);
  useFetchEmployeeData(vacancyDetails, fichaContext);

  useEffect(() => {
    const handleResize = () => {
      setHeight(elementRef.current.offsetHeight);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setHeight]);

  if (!fichaContext) {
    return <div>O contexto de ficha não está disponível.</div>;
  }

  let cellsPerPage = Math.max(Math.floor((height - 466) / 75), 3);
  if (cellsPerPage <= 0) cellsPerPage = 1;

  const handleNavigation = (component: string) => {
    const { sexoColaborador, dtNascimento, nacionalidade } = fichaContext.ficha;

    let isBlocked = false;

    switch (component) {
      case 'documentoDeIdentidade':
        if (!verificaIdentidade(nacionalidade)) {
          isBlocked = true;
        }
        break;
      case 'tituloDeEleitor':
        if (!verificaEleitor(dtNascimento, nacionalidade)) {
          isBlocked = true;
        }
        break;
      case 'certificadoReservista':
        if (!verificaReservista(sexoColaborador, dtNascimento, nacionalidade)) {
          isBlocked = true;
        }
        break;
      default:
        break;
    }

    if (!isBlocked) {
      navigate('/link/ficha-cadastral/preenchimento', { state: { component } });
    }
  };

  const getSubtitle = (component: string) => {
    const { sexoColaborador, dtNascimento, nacionalidade } = fichaContext.ficha;

    const isDtNascimentoNull = !dtNascimento;
    const isNacionalidadeNull = !nacionalidade;
    const isSexoColaboradorNull = !sexoColaborador;

    switch (component) {
      case 'documentoDeIdentidade':
        if (!verificaIdentidade(nacionalidade)) {
          if (isNacionalidadeNull) {
            return 'Necessário preencher Dados Pessoais';
          }
          return 'Candidato dispensado dessa etapa';
        }
        break;
      case 'tituloDeEleitor':
        if (!verificaEleitor(dtNascimento, nacionalidade)) {
          if (isDtNascimentoNull || isNacionalidadeNull) {
            return 'Necessário preencher Dados Pessoais';
          }
          return 'Candidato dispensado dessa etapa';
        }
        break;
      case 'certificadoReservista':
        if (!verificaReservista(sexoColaborador, dtNascimento, nacionalidade)) {
          if (isDtNascimentoNull || isNacionalidadeNull || isSexoColaboradorNull) {
            if (!isDtNascimentoNull && !isNacionalidadeNull) {
              return 'Necessário preencher Gênero';
            }
            return 'Necessário preencher Dados Pessoais e Gênero';
          }
          return 'Candidato dispensado dessa etapa';
        }
        break;
      default:
        return 'Etapa obrigatória';
    }

    return 'Etapa obrigatória';
  };

  const cardList = [
    { title: "Dados Pessoais", subtitle: "Etapa obrigatória", component: "dadosPessoais", isFilled: checkDadosPessoais(fichaContext?.ficha) },
    { title: "Gênero e Orientação", subtitle: "Etapa obrigatória", component: "generoOrientacao", isFilled: checkGeneroOrientacao(fichaContext?.ficha) },
    { title: "Estado Civil", subtitle: "Etapa obrigatória", component: "estadoCivil", isFilled: checkEstadoCivil(fichaContext?.ficha) },
    { title: "Etnia", subtitle: "Etapa obrigatória", component: "etnia", isFilled: checkEtnia(fichaContext?.ficha) },
    { title: "Grau de Instrução", subtitle: "Etapa obrigatória", component: "grauInstrucao", isFilled: checkGrauInstrucao(fichaContext?.ficha) },
    { title: "Pessoa com deficiência (PcD)", subtitle: "Etapa obrigatória", component: "pessoaComDeficiencia", isFilled: checkPessoaComDeficiencia(fichaContext?.ficha) },
    { title: "Endereço", subtitle: "Etapa obrigatória", component: "endereco", isFilled: checkEndereco(fichaContext?.ficha) },
    { title: "Informações de contato", subtitle: "Etapa obrigatória", component: "informacoesDeContato", isFilled: checkInformacoesContato(fichaContext?.ficha) },
    { title: "Dependentes", subtitle: "Etapa obrigatória", component: "dependentes", isFilled: checkDependentes(fichaContext?.ficha) },
    { title: "Dados bancários", subtitle: "Etapa obrigatória", component: "dadosBancarios", isFilled: checkDadosBancarios(fichaContext?.ficha) },
    { title: "Va/Vr", subtitle: "Etapa obrigatória", component: "VaVr", isFilled: checkVaVr(fichaContext?.ficha) },
    { title: "Vale transporte", subtitle: "Etapa obrigatória", component: "valeTransporte", isFilled: checkValeTransporte(fichaContext?.ficha) },
    { title: "Carteira de trabalho", subtitle: "Etapa obrigatória", component: "carteiraDeTrabalho", isFilled: checkCarteiraDeTrabalho(fichaContext?.ficha) },
    { title: "Documento de identidade", subtitle: getSubtitle("documentoDeIdentidade"), component: "documentoDeIdentidade", isFilled: checkDocumentoDeIdentidade(fichaContext?.ficha) },
    { title: "Título de eleitor", subtitle: getSubtitle("tituloDeEleitor"), component: "tituloDeEleitor", isFilled: checkTituloDeEleitor(fichaContext?.ficha) },
    { title: "Certificado de Reservista", subtitle: getSubtitle("certificadoReservista"), component: "certificadoReservista", isFilled: checkCertificadoReservista(fichaContext?.ficha) }
  ];

  const allFilledCheck = () => {
    return cardList.every((card) => card.isFilled);
  };
  const filledDocuments = cardList.filter(card => card.isFilled).length;
  const totalDocuments = cardList.length;

  const cardElements = cardList.map((card, index) => {
    const { sexoColaborador, dtNascimento, nacionalidade } = fichaContext.ficha;

    let isBlocked = false;

    switch (card.component) {
      case 'documentoDeIdentidade':
        if (!verificaIdentidade(nacionalidade)) {
          isBlocked = true;
        }
        break;
      case 'tituloDeEleitor':
        if (!verificaEleitor(dtNascimento, nacionalidade)) {
          isBlocked = true;
        }
        break;
      case 'certificadoReservista':
        if (!verificaReservista(sexoColaborador, dtNascimento, nacionalidade)) {
          isBlocked = true;
        }
        break;
      default:
        break;
    }

    return (
      <CardFichaCadastral
        key={index}
        onClick={() => handleNavigation(card.component)}
        title={card.title}
        subtitle={card.subtitle}
        disabled={isBlocked}
        filled={card.isFilled}
      />
    );
  });

  const handleFichaCadastralSubmit = async () => {
    const fichaAtualizada = {
      ...fichaContext?.ficha,
      fichaCadastralEnviada: "true",
    };

    try {
      const response = await sendEmployeeData(fichaAtualizada);
      if (response.fichaCadastralEnviada === "true") {
        swalAlert("success", "Ficha cadastral salva com sucesso!", "Ok");
        navigate("/link/home/");
      } else {
        console.error('Erro ao enviar ficha cadastral:', response.status);
        swalAlert("error", "Ocorreu um erro ao salvar a ficha cadastral. Tente novamente.", "Ok");
      }
    } catch (error) {
      console.error('Erro ao enviar ficha cadastral:', error);
      swalAlert("error", "Houve um erro ao salvar a ficha cadastral. Tente novamente.", "Ok");
    }
  };


  const slides: ReactNode[][] = [];
  if (cellsPerPage > 0) {
    for (let i = 0; i < cardElements.length; i++) {
      const slidesIndex = Math.floor(i / cellsPerPage);
      if (slides.length === slidesIndex) slides.push([]);
      slides[slidesIndex].push(cardElements[i]);
    }
  }

  const slideElements = slides.map((s, i) => (
    <SwiperSlide key={'page-' + i}>
      {s}
    </SwiperSlide>
  ));


  return (
    <Container maxWidth="xs" className={classes.container} ref={elementRef}>
      <Grid style={{ marginBottom: 16 }}>
        <GoBackHeader
          onGoBack={() => (navigate("/link/home"))}
        />
      </Grid>
      <div className={classes.content}>
        <div className={classes.titleContent}>
          <Typography variant="h6" className={classes.title}>
            Ficha cadastral
          </Typography>
          <Typography variant="body2" className={classes.description}>
            Preencha todas as etapas obrigatórias
          </Typography>
        </div>

        {loading ? (
          <Skeleton variant="rectangular" width={'100%'} height={160} />
        ) : (
          <TitleVacancyDetails
            vacancyDetails={vacancyDetails}
            filledDocuments={filledDocuments}
            totalDocuments={totalDocuments}
          />
        )}

        <div className={classes.sliderContainer}>
          {loading ? (
            <Grid container spacing={2}>
              {[...Array(3)].map((_, index) => (
                <Grid item width={'100%'} key={index}>
                  <Skeleton variant="rectangular" height={60} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Swiper
              slidesPerView={1}
              spaceBetween={32}
              onSlideChange={(swiper) => {
                const currentPage = swiper.realIndex;
                sessionStorage.setItem('currentPageFichaSwiper', currentPage.toString());
                setPageFicha(currentPage);
              }}
              onSwiper={setSwiper}
              initialSlide={parseInt(sessionStorage.getItem('currentPageFichaSwiper') || '0')}
            >
              {slideElements}
            </Swiper>
          )}
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignSelf: 'stretch', marginBottom: 16 }}>
          {!loading && (
            <Pagination
              length={slideElements.length}
              position={pageFicha}
              color="#1d3054"
              onSelected={(n) => swiper.slideTo(n)}
              marginBottom={0}
            />
          )}
        </div>

        <ConfirmButton disabled={!allFilledCheck()} onClick={handleFichaCadastralSubmit}>
          CONCLUIR
        </ConfirmButton>
      </div>
    </Container>
  );

};

export default FichaCadastral;
