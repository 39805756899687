import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetVacancyDetails } from '../../../services/api';
import { swalAlert } from '../../../helpers/swalAlert';

export const useFetchVacancyDetail = (nrChamado: any, setVacancyDetails: any, vacancyDetails: any, setLoading: any, loading: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVacancyDetail = async () => {
      let response;
      if (!nrChamado) {
        swalAlert("error", "Vaga não encontrada, entre novamente pelo Link enviado para você", "Ok");
        navigate("/link/carregar/");
        return;
      }
      response = await GetVacancyDetails(nrChamado);
      if (response) {
        setVacancyDetails(response);
      } else {
        swalAlert("error", "Erro ao buscar detalhes da vaga, tente novamente", "Tentar novamente");
        const targetRoute = '/link/';
        setTimeout(() => navigate(targetRoute), 2000);
      }
      setLoading(false);
    };

    fetchVacancyDetail();
  }, [nrChamado]);

  return { vacancyDetails, loading };
};
