import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorInterface from "../../../components/ErrorInterface";
import LoadingInterface from "../../../components/LoadingInterface"
import useDocumentLink from "../../../hooks/useDocumentLink"
import { verifyLink } from "../../../services/api";


function LoadingLink() {
  const { setUser } = useDocumentLink();
  const [ error, setError] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() =>
  {
    const load = async () =>
    {
      const key = sessionStorage.getItem('url-link-key')
      if(key)
      {
        try
        {
          const user = await verifyLink(key)
          setUser(user)
          sessionStorage.setItem('nrChamadoLink', user.nrChamado)
          navigate('/link/dados-acesso')
        }
        catch
        {
          setError(true)
        }
      }
      else
      {
        setError(true)
      }
    }
    load()
  }, [setUser, setError, navigate])
  return (
      <>
        { error ? <ErrorInterface/> : <LoadingInterface/> }
      </>
  )
}

export default LoadingLink
