import { makeStyles } from "@mui/styles"
import { Typography } from "@mui/material"

import {
  ArrowBack
} from "@mui/icons-material"
import { useNavigate } from "react-router-dom"


const GoBack = () => {
  const navigate = useNavigate()
  const useStyles = makeStyles(theme => ({

    option: {
      flex: 1,
      padding: 16,
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer'
      }
    },

    label: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#003da5",
      fontWeight: 700,
      marginLeft: 8
    }

  }))

  const classes = useStyles()

  return (
    <div
      className={classes.option}
      onClick={() => {
        navigate(-1);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          navigate(-1);
        }
      }}
      tabIndex={0}
    >
      <ArrowBack style={{ color: "#003da5" }} />
      <Typography variant='h6' className={classes.label}>
        Voltar
      </Typography>
    </div>
  );
}

export default GoBack
