import { makeStyles, withStyles } from "@mui/styles"

import { Grid, Typography, LinearProgress, Container, Tooltip } from "@mui/material"
import ConfirmButton from "../../components/ConfirmButton"
import {
  BadgeOutlined,
  ChevronRight,
  QuestionMarkOutlined,
} from "@mui/icons-material"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UploadIcon from '@mui/icons-material/Upload';
import RefreshIcon from '@mui/icons-material/Refresh';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { useNavigate } from "react-router-dom"

import useAuth from "../../hooks/useAuth"
import useTabs from "../../hooks/useTabs"
import useAlert from "../../hooks/useAlert"
import useModal from "../../hooks/useModal"
import useDocumentLink from "../../hooks/useDocumentLink"
import { IDocuments } from "../../@types/Documents"
import { swalAlert } from "../../helpers/swalAlert"
import GoBackHeader from "../../components/GoBackHeader"
import { ReactNode, useEffect, useRef, useState } from "react";
import Pagination from "../../components/Pagination";
import { GetVacancyDocuments, SendToCentral, GetVacancyDetails } from "../../services/api";
import { showFormatedCpf, calculateProgressBar, formatDateToBrazilianPattern } from "../../helpers/format";
import { getMostRecentDocument, statusText } from "../../helpers/documentHelpers";
import MainButton from "../../components/MainButton";
import { IVacancy } from "../../@types/Vacancies";
import Header from "../../components/Header";
import useIsLinkRoute from "../../hooks/useIsLinkRoute";
import DeleteClearButton from "../../components/DeleteClearButton";

const WhiteTooltip = withStyles(theme => ({
  tooltip: {
    border: "1px solid",
    background: "#FFF",
    borderColor: theme.palette.secondary.main,
  },
  tooltipPlacementBottom: {
    marginTop: 0,
  },
}))(Tooltip)

export default function Documents() {

  const spinKeyframes = {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  };

  const spinAnimation = {
    animation: "$spin 2s infinite linear",
  };

  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'stretch',
      height: '100vh',
      paddingLeft: 0,
      paddingRight: 0,
    },
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      minHeight: 447,
      marginTop: 24,
      marginLeft: 16,
      marginRight: 16,
      marginBottom: 67,
      filter: modal?.removeAllDocument ? "blur(3px)" : undefined,
    },
    sliderContainer: {
      maxWidth: 412,
      width: '91vw',
      marginTop: 24,
    },
    title: {
      fontSize: 20,
      lineHeight: "30px",
      fontWeight: 600,
      color: '#1d3054'
    },
    description: {
      fontSize: 14,
      lineHeight: "20px",
      color: '#4d4d4d'
    },
    messageDocuments: {
      fontSize: 12,
      lineHeight: "18px",
      marginTop: 12,
      display: 'flex',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
      gap: 4,
      alignSelf: 'stretch',
      borderRadius: 8,
    },
    titleContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,
    },
    spin: spinAnimation,
    "@keyframes spin": spinKeyframes,
    spacer: { minHeight: 24 }
  }))

  const navigate = useNavigate()
  const { setTab } = useTabs()
  const { setImages } = useAuth()
  const { alert, setAlert } = useAlert()
  const { modal, setModal } = useModal()
  const [documents, setDocuments] = useState<IDocuments[]>([])
  const [sentDocuments, setSentDocuments] = useState<number>(0)
  const [totalDocuments, setTotalDocuments] = useState<number>(0)
  const [vacancyDetails, setVacancyDetails] = useState<any>({})
  const [closeTooltips, setCloseTooltips] = useState<boolean>(false)
  const [attachedDocuments, setAttachedDocuments] = useState<number>(0)
  const { user } = useDocumentLink();
  const classes = useStyles()
  const [height, setHeight] = useState(0);
  const elementRef = useRef<any>(null)
  const [swiper, setSwiper] = useState<any>(null);
  const [page, setPage] = useState<any>(0);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const nrChamado = vacancyDetails?.nrChamado || user.nrChamado || sessionStorage.getItem("nrChamadoLink");

  const [vacancy, setVacancy] = useState<IVacancy>({} as IVacancy)
  const isLinkRoute = useIsLinkRoute();


  useEffect(() => {
    const handleResize = () => {
      setHeight(elementRef.current.offsetHeight);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [setHeight])

  let cellsPerPage = Math.max(Math.floor((height - 466) / 90), 3);

  if (cellsPerPage <= 0) cellsPerPage = 1
  /* console.log('Devo renderizar ' + cellsPerPage + ' por slide') */

  const documentCards = documents.map((document, index) => (
    <DocumentCard
      key={document.id}
      document={document}
      navigate={navigate}
      setDocuments={setDocuments}
      setModal={setModal}
      modal={modal}
      setCloseTooltips={setCloseTooltips}
      closeTooltips={closeTooltips}
      documents={documents}
      isLinkRoute={isLinkRoute}
    />
  ));

  const slides: ReactNode[][] = []
  if (cellsPerPage > 0) {
    for (let i = 0; i < documentCards.length; i++) {
      const slidesIndex = Math.floor(i / cellsPerPage)
      if (slides.length === slidesIndex) slides.push([])
      slides[slidesIndex].push(documentCards[i])
    }
  }

  const slideElements = slides.map((s, i) => (
    <SwiperSlide key={'page-' + i}>
      {s}
    </SwiperSlide>
  ))

  const getVacancyDetail = async () => {
    let response;
    if (isLinkRoute) {
      if (!nrChamado) {
        swalAlert("error", "Vaga não encontrada, entre novamente pelo Link enviado para você", "Ok");
        navigate("/link/carregar/")
        return;
      }
      response = await GetVacancyDetails(nrChamado);
    } else {
      if (!localStorage.getItem("selectedVacancy")) {
        swalAlert("error", "Vaga não encontrada", "Ok");
        navigate("/auth/vagas-em-andamento")
        return;
      }
      let vacancyNumber = JSON.parse(localStorage.getItem("selectedVacancy") as string).nrChamado
      response = await GetVacancyDetails(vacancyNumber);
    }
    if (response) {
      setVacancyDetails(response);
    } else {
      swalAlert("error", response, "Tentar novamente");
      const targetRoute = isLinkRoute ? '/link/' : '/auth/';
      setTimeout(() => navigate(targetRoute), 2000);
    }
  }


  const getDocuments = async () => {
    setIsRefreshing(true);
    setAlert({ ...alert, waitingResponse: true });
    let response;
    if (isLinkRoute) {
      if (!nrChamado) {
        swalAlert("error", "Vaga não encontrada, entre novamente pelo Link enviado para você", "Ok");
        navigate("/link/carregar/")
        return;
      }
      response = await GetVacancyDocuments(nrChamado);
    } else {
      let vacancyNumber = JSON.parse(localStorage.getItem("selectedVacancy") as string).nrChamado;
      response = await GetVacancyDocuments(vacancyNumber);
    }

    const targetRoute = isLinkRoute ? '/link/documentos' : '/auth/vagas-em-andamento';

    if (response?.status >= 200 && response?.status < 300) {
      setTotalDocuments(response.data.qtdeDocumentoTotal);
      setAttachedDocuments(response.data.qtdeDocumentoAnexado);
      setSentDocuments(response.data.qtdeDocumentoEnviadoCentral);
      setDocuments(response.data.listaDocumentosFormatada);
      setAlert({ ...alert, waitingResponse: false });
    } else {
      swalAlert("error", response, "TENTAR NOVAMENTE");
      setTimeout(() => navigate(targetRoute), 2000);
    }

    setAlert({ ...alert, waitingResponse: false });
    setIsRefreshing(false);
  }



  useEffect(() => {
    setVacancy(JSON.parse(localStorage.getItem("selectedVacancy") as string));

    localStorage.setItem("backPath", "/auth/vagas-em-andamento")

    setTab("1");

    setImages({ imagesArray: [] });

    (async function loadDocs() { await getDocuments() }())
    getVacancyDetail();

  }, [])

  const initialPage = sessionStorage.getItem('currentPageSwiper');
  const initialPageNumber = initialPage ? parseInt(initialPage, 10) : 0;

  const sendDocuments = async () => {
    setIsSending(true);
    setAlert({ ...alert, waitingResponse: true });
    let response = await SendToCentral(vacancyDetails?.nrChamado);
    if (response?.status >= 200 && response?.status < 300) {
      swalAlert(
        "success",
        `<strong>Documentos enviados com sucesso!</strong><br/>
        Os documentos da vaga <strong>${vacancyDetails.nome}</strong> foram enviados com sucesso para a <strong>Central</strong>. Você receberá um e-mail de confirmação. Você pode fechar essa página agora.`,
        "OK, ENTENDI"
      );
      setAlert({ ...alert, waitingResponse: false });
    } else {
      setAlert({ ...alert, waitingResponse: false });
      swalAlert("error", response, "TENTAR NOVAMENTE");
    }
    localStorage.setItem("slide", "0");
    setIsSending(false);
    getDocuments();
  };

  const saveDocuments = async () => {
    try {
      const response = disableSentToCentralButton();
      if (response === false) {
        swalAlert("success", "Documentos salvos com sucesso!", "Ok");
        navigate("/link/home/");
      } else {
        console.error('Erro ao salvar os Documentos', response);
        swalAlert("error", "Ocorreu um erro ao salvar os documentos. Tente novamente.", "Ok");
      }
    } catch (error) {
      console.error('Erro ao salvar os Documentos', error);
      swalAlert("error", "Houve um erro ao salvar os documentos. Tente novamente.", "Ok");
    }
  };



  const disableSentToCentralButton = () => {
    const toCentral = sentDocuments + attachedDocuments
    if (sentDocuments >= totalDocuments || attachedDocuments >= totalDocuments || toCentral >= totalDocuments) {
      return false
    } else {
      return true
    }
  }

  const areAllMandatoryDocumentsApproved = (documents: IDocuments[]): boolean => {
    for (const document of documents) {
      const mostRecentDocument = getMostRecentDocument(document);
      if (mostRecentDocument && (mostRecentDocument.status === "Reprovado" || mostRecentDocument.status === "Aguardando Textract" || (document.statusDoUsuario === "Documento Pendente" &&
        document.justificativa !== null &&
        document.justificativa !== ""))) {
        return false;
      }
      if (document.obrigatorio) {
        if (document.statusDoUsuario === "Documento Enviado") {
          continue;
        }
        if (
          mostRecentDocument &&
          (mostRecentDocument.status === "Aprovado" ||
            mostRecentDocument.status === "Aprovado Sem Textract" ||
            mostRecentDocument.status === "Aprovado Textract")
        ) {
          continue;
        } else {
          return false;
        }
      }
    }
    return true;
  };

  const hasDocumentAttached = (documents: IDocuments[]): boolean => {
    for (const document of documents) {
      if (document.statusDoUsuario === "Documento Anexado") {
        return true;
      }
    }
    return false;
  };

  const isSendButtonDisabled = () => {
    return (
      !areAllMandatoryDocumentsApproved(documents) ||
      !hasDocumentAttached(documents) ||
      disableSentToCentralButton() ||
      isSending
    );
  };

  const countDocumentsWithStatus = (documents: IDocuments[], targetStatus: string): number => {
    let count = 0;
    for (const document of documents) {
      const mostRecentDocument = getMostRecentDocument(document);
      if (mostRecentDocument && mostRecentDocument.status === targetStatus) {
        count++;
      }
    }
    return count;
  };

  const countDocumentsWithStatusDoUsuario = (documents: IDocuments[], targetStatus: string): number => {
    let count = 0;
    for (const document of documents) {
      if (document.statusDoUsuario === targetStatus && document.justificativa !== null && document.justificativa !== "") {
        count++;
      }
    }
    return count;
  };

  const handleClearVacancy = () => {
    localStorage.setItem("backPath", "/auth/documentos")
    setModal({ ...modal, removeAllDocument: true })
  }

  const backToPath = () => {
    navigate(localStorage.getItem("backPath") as string)
    localStorage.setItem('slide', "0")
  }

  return (
    <Container maxWidth="xs" className={classes.container} ref={elementRef}>
      {!isLinkRoute && (
        <Grid style={{ backgroundColor: "#1C2F54" }}>
          <Header />
        </Grid>
      )}
      <GoBackHeader
        onGoBack={() => (isLinkRoute ? navigate("/link/home") : backToPath())}
      />
      <div className={classes.content}>
        <div className={classes.titleContent}>
          <div>
            <Typography variant="h6" className={classes.title}>
              Documentos necessários
            </Typography>
            <Typography variant="body2" className={classes.description}>
              Envie todos os documentos obrigatórios.
            </Typography>
          </div>
          <RefreshIcon
            style={{
              fontSize: 20,
              color: '#1d3054',
              cursor: 'pointer',
            }}
            className={isRefreshing ? classes.spin : ''}
            onClick={() => getDocuments()}
          />
        </div>
        {vacancyDetails && totalDocuments !== 0 && (
          <JobCard
            vacancyDetails={vacancyDetails}
            totalDocuments={totalDocuments}
            sentDocuments={sentDocuments}
            attachedDocuments={attachedDocuments}
          />
        )}
        {countDocumentsWithStatus(documents, "Reprovado") > 0 && (
          <div className={classes.messageDocuments} style={{ color: '#b91c1c', backgroundColor: '#FEE2E2' }}>
            <ErrorOutlineIcon style={{ fontSize: 16, marginRight: 8 }} />
            <p>Nº de documentos <strong>recusados</strong> pela central: {countDocumentsWithStatus(documents, "Reprovado") + countDocumentsWithStatusDoUsuario(documents, "Documento Pendente")}</p>
          </div>
        )}
        {countDocumentsWithStatus(documents, "Aguardando Textract") > 0 && (
          <div className={classes.messageDocuments} style={{ backgroundColor: '#FEF9C3', color: '#854D0E', }}>
            <UploadIcon style={{ fontSize: 16, marginRight: 8 }} />
            <p> Nº de documentos <strong>aguardando análise</strong> pela central: <strong> {countDocumentsWithStatus(documents, "Aguardando Textract")}.</strong><br /> Se o processamento estiver demorando mais do que o normal, <strong>atualize a página.</strong></p>
          </div>
        )}
        <div style={{ flex: 1 }}>
          <div className={classes.sliderContainer}>
            <Swiper
              slidesPerView={1}
              spaceBetween={32}
              onSlideChange={(swiper) => {
                const currentPage = swiper.realIndex;
                sessionStorage.setItem('currentPageSwiper', currentPage.toString());
                setPage(currentPage);
              }}
              onSwiper={setSwiper}
              initialSlide={initialPageNumber}
            >
              {slideElements}
            </Swiper>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignSelf: 'stretch', marginTop: 8 }}>
            <Pagination
              length={slideElements.length}
              position={page}
              color='#1d3054'
              onSelected={(n) => swiper.slideTo(n)}
              marginBottom={0}
            />
          </div>

        </div>
        {!isRefreshing && (
          <ConfirmButton
            onClick={() => (isLinkRoute ? saveDocuments() : sendDocuments())}
            disabled={isSendButtonDisabled()}
          >
            {isLinkRoute
              ? (isSending ? "Salvando documentos..." : "SALVAR DOCUMENTOS")
              : (isSending ? "Enviando para a central..." : "ENVIAR PARA CENTRAL")}
          </ConfirmButton>
        )}

        {!isLinkRoute && (
          <MainButton
            fullWidth
            size='small'
            style={{ marginBottom: 8, marginTop: 8, borderRadius: 8, height: 52, lineHeight: '52px', fontWeight: 700, fontSize: 16 }}
            buttonStyle='errorOutlined'
            onClick={handleClearVacancy}
            disabled={sentDocuments === totalDocuments || sentDocuments > 0}
          >
            DELETAR DOCUMENTOS
          </MainButton>
        )}

        <div className={classes.spacer}>
        </div>
      </div>

      <DeleteClearButton
        open={modal.removeAllDocument}
        close={() => setModal({ ...modal, removeAllDocument: false })}
        typeButton='clearVacancy'
      />
    </Container>
  )

};

interface JobDetails {
  id: number;
  nome: string;
  nrChamado: string;
  dataAdmissao: string;
  empregado: {
    nome: string;
    cpf: string;
  }
}

const JobCard = ({
  vacancyDetails,
  totalDocuments,
  sentDocuments,
  attachedDocuments
}: {
  vacancyDetails: JobDetails;
  totalDocuments: number;
  sentDocuments: number;
  attachedDocuments: number;
}) => {
  const useStyles = makeStyles(theme => ({
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      padding: 16,
      paddingBottom: 12,
      borderRadius: 8,
      backgroundColor: '#fff',
      boxShadow: "0px 2px 4px -2px rgba(77, 77, 77, 0.06), 0px 4px 8px -2px rgba(77, 77, 77, 0.10)"
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
      color: '#1d3054'
    },
    item: {
      fontSize: 12,
      lineHeight: "18px",
      fontWeight: 400,
      color: '#1d3054',
      marginBottom: 4
    }
  }))

  const classes = useStyles()

  if (!vacancyDetails) {
    return null;
  }

  return (
    <div className={classes.card}>
      <div className={classes.titleContainer}>
        <Typography variant="body2" className={classes.title}>
          {vacancyDetails.nome}
        </Typography>
        <Typography variant="body2" className={classes.title}>
          {sentDocuments + attachedDocuments}/{totalDocuments} docs
        </Typography>
      </div>
      <LinearProgress
        color="primary"
        variant="determinate"
        style={{ borderRadius: 8, height: 8, marginTop: 12, marginBottom: 12 }}
        value={calculateProgressBar(sentDocuments + attachedDocuments, totalDocuments)}
      />
      <Typography variant="body2" className={classes.item}>
        <strong>Nº de chamado:</strong> {vacancyDetails.nrChamado}
      </Typography>
      <Typography variant="body2" className={classes.item}>
        <strong>Data de admissão:</strong> {formatDateToBrazilianPattern(vacancyDetails?.dataAdmissao)}
      </Typography>
      <Typography variant="body2" className={classes.item}>
        <strong>Empregado:</strong> {vacancyDetails.empregado?.nome || 'N/A'}
      </Typography>
      <Typography variant="body2" className={classes.item}>
        <strong>CPF:</strong> {showFormatedCpf(vacancyDetails.empregado?.cpf) || 'N/A'}
      </Typography>
    </div>
  );
};

interface DocumentCardProps {
  document: IDocuments;
  navigate: (path: string) => void;
  setDocuments: (documents: IDocuments[]) => void;
  setModal: (modal: any) => void;
  modal: any;
  setCloseTooltips: (closeTooltips: boolean) => void;
  closeTooltips: boolean;
  documents: IDocuments[];
  isLinkRoute: boolean;

}

const DocumentCard: React.FC<DocumentCardProps> = ({ document, navigate, isLinkRoute, setDocuments, documents }) => {

  const useStyles = makeStyles(theme => ({
    card: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      padding: 16,
      borderRadius: 8,
      backgroundColor: '#fff',
      boxShadow: "0px 2px 4px -2px rgba(77, 77, 77, 0.06), 0px 4px 8px -2px rgba(77, 77, 77, 0.10)",
      '&:hover': {
        cursor: 'pointer'
      },
      marginBottom: 8
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#e8e8e8',
      height: 40,
      width: 40,
      borderRadius: 4
    },
    textContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginLeft: 8,
      marginRight: 8
    },
    title: {
      color: '#1d3054',
      lineHeight: "20px",
      fontSize: 14,
      fontWeight: 700,
    },
    text: {
      color: '#4d4d4d',
      lineHeight: "18px",
      fontSize: 12,
      fontWeight: 400,
    },
    chevronContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 4
    },
    iconCard: {
      padding: 8,
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      color: "#15803D",
      background: "#DCFCE7",
      justifyContent: "center",
    },
    iconCardAttached: {
      padding: 8,
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      color: "#854D0E",
      background: "#FEF9C3",
      justifyContent: "center",
    },
    iconCardError: {
      padding: 8,
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      color: "#B91C1C",
      background: "#FEE2E2",
      justifyContent: "center",
    },
    iconCardNotSent: {
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      background: "#e8e8e8",
      justifyContent: "center",
    },
    infosAttached: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 4,
      width: '100%',
    },
    cardClickable: {
      cursor: 'pointer',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }
  }))

  const classes = useStyles()

  const renderStatusIcon = () => {
    if (mostRecentDocument?.status === "Aprovado" ||
      mostRecentDocument?.status === "Aprovado Sem Textract" ||
      mostRecentDocument?.status === "Aprovado Textract" ||
      document?.statusDoUsuario === "Documento Enviado") {
      return <CheckIcon style={{ fontSize: 16, marginRight: 8 }} />;
    } else if (mostRecentDocument?.status === "Aguardando Textract") {
      return <UploadIcon style={{ fontSize: 16, marginRight: 8 }} />;
    } else {
      return <ErrorOutlineIcon style={{ fontSize: 16, marginRight: 8 }} />;
    }
  };

  const documentType = (document: IDocuments) => {
    localStorage.setItem("documentToUpload", JSON.stringify(document));
    const pathToAnexar = isLinkRoute
      ? "/link/documentos/anexar"
      : "/auth/documentos/anexar";
    navigate(pathToAnexar);
  };

  const handleTooltip = (document: IDocuments) => {
    let newDocs = [...documents]

    newDocs.forEach(temp => {
      if (temp.nome === document.nome) {
        if (temp.openTooltip) {
          temp.openTooltip = false
        } else {
          temp.openTooltip = true
        }
      }
    })

    setDocuments(newDocs)
  }

  const mostRecentDocument = getMostRecentDocument(document);

  const statusLabel = (document.statusDoUsuario === "Documento Enviado")
    ? "Enviado"
    : (document.statusDoUsuario === "Documento Pendente")
      ? "Reprovado"
      : statusText[mostRecentDocument?.status as keyof typeof statusText];

  const documentCardColor = () => {
    const status = mostRecentDocument?.status || '';
    const statusEnviado = document.statusDoUsuario || '';

    if (status === "Aprovado Textract" || status === "Aprovado" || status === "Aprovado Sem Textract" || statusEnviado === "Documento Enviado") {
      return classes.iconCard;
    } else if (status === "Aguardando Textract" || status === "Pendente") {
      return classes.iconCardAttached;
    } else if (status === "Reprovado" || (statusEnviado === "Documento Pendente" && document.justificativa !== null && document.justificativa !== "")) {
      return classes.iconCardError;
    } else {
      return classes.iconCardNotSent;
    }
  }

  return (
    <div
      className={classes.card}
      key={document.nome}
    >
      {document.statusDoUsuario === "Documento Pendente" &&
        document.justificativa !== null &&
        document.justificativa !== "" ? (
        <WhiteTooltip
          arrow
          open={document?.openTooltip !== undefined && document?.openTooltip}
          title={
            <>
              <Grid container>
                <Typography
                  variant='body2'
                  color='secondary'
                  style={{ fontWeight: 500, marginBottom: 4 }}
                >
                  Documento reprovado
                </Typography>
              </Grid>
              <Grid container>
                <Typography variant='caption' color='primary'>
                  {document?.justificativa}
                </Typography>
              </Grid>
            </>
          }
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Grid
            item
            className={documentCardColor() + " " + classes.icon}
            /* onClick={() => handleTooltip(document)} */
            onMouseEnter={() => handleTooltip(document)}
            onMouseLeave={() => handleTooltip(document)}
            onClick={() => handleTooltip(document)}
          >
            <BadgeOutlined style={{ color: '#1d3054', fontSize: 16 }} />
            <QuestionMarkOutlined style={{ color: '#1d3054', fontSize: 12 }} />
          </Grid>
        </WhiteTooltip>
      ) : (
        <Grid
          item
          className={documentCardColor() + " " + classes.icon}>
          <BadgeOutlined style={{ color: '#1d3054', fontSize: 24 }} />
        </Grid>
      )}
      <div
        onClick={() => documentType(document)}
        tabIndex={0}
        role="button"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            documentType(document);
          }
        }}
        className={classes.cardClickable}
      >
        <div className={classes.textContainer}
        >
          <Typography variant="body2" className={classes.title}>
            {document.nome}
          </Typography>
          <Typography variant="body2" className={classes.text}>
            {document.nome === "Certificado de reservista" ? "Documento obrigatório (para homens)" : document.obrigatorio ? (<>
              Documento obrigatório <span style={{ color: '#b91c1c', display: 'inline' }}>*</span>
            </>) : ("Documento opcional")}
          </Typography>
          <div className={classes.infosAttached}>
            {(
              document.statusDoUsuario === "Documento Enviado" ||
              document.statusDoUsuario === "Documento Anexado" ||
              (document.statusDoUsuario === "Documento Pendente" &&
                document.justificativa !== null &&
                document.justificativa !== "")
            ) && (
                <Typography variant="body2" style={{
                  color: '#848484',
                  backgroundColor: '#F3F4F6',
                  borderRadius: 50,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 4,
                  paddingBottom: 4,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: 12,
                }}>
                  <AttachFileIcon style={{
                    marginRight: 8,
                    fontSize: 16,
                  }} />
                  Anexo
                </Typography>
              )}
            {statusLabel && (
              document.statusDoUsuario === "Documento Enviado" ||
              document.statusDoUsuario === "Documento Anexado" ||
              (document.statusDoUsuario === "Documento Pendente" &&
                document.justificativa !== null &&
                document.justificativa !== "")
            ) && (
                <Typography variant="body2" style={{
                  fontSize: 12,
                  display: 'flex',
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 4,
                  paddingBottom: 4,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 50,
                }} className={documentCardColor()}>
                  {renderStatusIcon()}
                  {statusLabel}
                </Typography>
              )}
          </div>
        </div>
        <div className={classes.chevronContainer}>
          <ChevronRight style={{ color: '#1d3054' }} />
        </div>
      </div>
    </div>
  );
};