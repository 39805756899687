
import { makeStyles } from "@mui/styles"
import {
  ArrowBack
} from "@mui/icons-material"
import { Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

type GoBackHeaderProps = {
  onGoBack?: () => void
  disabled?: boolean
}
const GoBackHeader = ({ onGoBack, disabled }: GoBackHeaderProps) => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 16,
      borderBottom: "1px solid #d1d1d1",
      '&:hover': {
        cursor: 'pointer'
      }
    },
    containerEmpty: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 28,
      borderBottom: "1px solid #d1d1d1",
      '&:hover': {
        cursor: 'pointer'
      }
    },

    icon: {

      color: '#1d3054',
      width: 24,
      height: 24
    },

    text: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 600,
      color: '#1d3054',
      paddingLeft: 4
    }
  }))

  const classes = useStyles()
  const navigate = useNavigate()


  return (
    <>
      {disabled ? <div
        className={classes.containerEmpty}
      >
        <Typography variant="body2" className={classes.text}>

        </Typography>
      </div> : <div
        className={classes.container}
        onClick={onGoBack ? onGoBack : () => navigate(-1)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (onGoBack) {
              onGoBack();
            } else {
              navigate(-1);
            }
          }
        }}
        tabIndex={0}
      >
        <ArrowBack className={classes.icon} />
        <Typography variant="body2" className={classes.text}>
          Voltar
        </Typography>
      </div>}
    </>
  );

}

export default GoBackHeader;