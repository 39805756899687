import { useNavigate } from "react-router-dom"

import { Grid, Theme, Typography } from "@mui/material"

import { makeStyles } from "@mui/styles"

import { FaChevronCircleLeft } from "react-icons/fa"

import useAlert from "../../hooks/useAlert"
import Header from "../../components/Header"
import { ClearVacancy } from "../../services/api"
import MainPaper from "../../components/MainPaper"
import { swalAlert } from "../../helpers/swalAlert"
import useVacancies from "../../hooks/useVacancies"
import MainButton from "../../components/MainButton"
import VacancyInfo from "../../components/VacancyInfo"
import MobileContainer from "../../components/MobileContainer"
import BackdropLoading from "../../components/BackdropLoading"
import { requestVacanciesBySections } from "../../services/getVacancies"

export default function ClearVacancyData() {
  const navigate = useNavigate()

  const { setVacancies } = useVacancies()

  const { alert, setAlert } = useAlert()

  const useStyles = makeStyles((theme: Theme) => ({
    paperContainer: {
      padding: 24,
    },
    card: {
      padding: 12,
      borderRadius: 8,
      marginBottom: 12,
      border: "2px solid",
      borderColor: theme.palette.primary.main,
    },
    takeVacancy: {
      color: "#000",
      marginLeft: 12,
      fontWeight: 700,
    },
    background: {
      top: 0,
      right: 0,
      margin: 0,
      padding: 0,
      zIndex: -1,
      left: "50%",
      maxWidth: 444,
      width: "101%",
      height: "101%",
      transition: "1s",
      maxHeight: "101%",
      position: "fixed",
      objectFit: "none",
      transform: "translateX(-50%)",
    },
  }))

  const classes = useStyles()

  const handleClearVacancy = async () => {
    setAlert({ ...alert, waitingResponse: true })

    let selectedVacancy = JSON.parse(localStorage.getItem("selectedVacancy") as string)

    let response = await ClearVacancy(selectedVacancy?.nrChamado)

    if (response?.status >= 200 && response?.status < 300) {
      let vacanciesResponse = await requestVacanciesBySections()

      setVacancies(vacanciesResponse)

      setAlert({ ...alert, waitingResponse: false })

      swalAlert(
        "success",
        `Os dados da vaga <strong>${selectedVacancy?.nome}</strong> foram apagados.`,
        "OK, ENTENDI"
      )

      setTimeout(() => navigate("/auth/novas-vagas"), 1000)
    } else {
      setAlert({ ...alert, waitingResponse: false })

      swalAlert("error", response, "TENTAR NOVAMENTE")
    }
  }

  return (
    <MobileContainer>
      <img
        alt='Background'
        className={classes.background}
        src='/assets/images/loginBackgroundVermelho.png'
      />

      <Header />
      <MainPaper height='82vh' overflow>
        <Grid container className={classes.paperContainer}>
          <Grid container className={classes.card}>
            <Grid container alignItems='center' style={{ marginBottom: 12 }}>
              <FaChevronCircleLeft
                size={24}
                onClick={() => navigate(localStorage.getItem("backPath") as string)}
              />

              <Typography variant='h6' className={classes.takeVacancy}>
                Limpar dados da vaga
              </Typography>
            </Grid>

            <VacancyInfo />

            <Typography variant='caption' color='secondary' style={{ marginTop: 16 }}>
              <strong>Tem certeza de que quer limpar os dados anexados a essa vaga? </strong>
            </Typography>

            <Typography variant='caption' color='secondary' style={{ marginTop: 8 }}>
              Se você não pretende limpar os dados dessa vaga, clique em <strong>"VOLTAR"</strong>.
              Se quiser confirmar a remoção clique em <strong>"LIMPAR DADOS DA VAGA"</strong>.
            </Typography>

            <MainButton
              fullWidth
              size='small'
              buttonStyle='error'
              style={{ marginTop: 12 }}
              onClick={handleClearVacancy}
            >
              Limpar dados da vaga
            </MainButton>

            <MainButton
              fullWidth
              size='small'
              style={{ marginTop: 8 }}
              buttonStyle='outlined'
              onClick={() => navigate(localStorage.getItem("backPath") as string)}
            >
              Voltar
            </MainButton>
          </Grid>
        </Grid>
      </MainPaper>

      <BackdropLoading open={alert?.waitingResponse} />
    </MobileContainer>
  )
}
