export const TOKEN_KEY = "@PortalAdm-Token"

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null

export const getToken = () => localStorage.getItem(TOKEN_KEY)

export const login = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem("tempT")
  localStorage.removeItem("externalId")
  localStorage.removeItem("email")
}
