import React, { ReactNode } from "react"

import { Container, Theme } from "@mui/material"

import { makeStyles } from "@mui/styles"

interface MobileContainerProps {
  children: ReactNode
  blueBackground?: boolean
  noOverflow?: boolean
  style?: React.CSSProperties
}

export default function MobileContainer({
  children,
  blueBackground,
  noOverflow,
  style,
}: MobileContainerProps) {
  // @ts-ignore
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      top: blueBackground && 0,
      bottom: blueBackground && 0,
      left: blueBackground && "50%",
      width: blueBackground && "101%",
      position: blueBackground && "fixed",
      transform: blueBackground && "translateX(-50%)",
      background: blueBackground && 'linear-gradient(to bottom, #1D3054 0%, #233C6F 100%)',
      height: blueBackground ? "100vh" : window.innerHeight - 24,
      ...style,
      "&::-webkit-scrollbar": {
        width: "12px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c8c8c8",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#ffffff",
      },
    },
  }))

  const classes = useStyles()
  return (
    <Container maxWidth='xs' className={classes.container}>
      {children}
    </Container>
  )
}
