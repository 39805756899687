import React, { useState, useContext, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import { TextField, Button, Grid, Typography } from '@mui/material';
import { Ficha } from '../typesFicha';
import './Componentes.css';

interface Props {
  onSave: (componentName: string, data: Partial<Ficha>) => void;
  onExit: (componentName: string, data: Partial<Ficha>) => void;
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

const cleanPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/\D/g, '');
};

const InformacoesDeContato: React.FC<Props> = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);
  const [formData, setFormData] = useState({
    email: fichaContext?.ficha.email || '',
    telefone: cleanPhoneNumber(fichaContext?.ficha.telefone || ''),
    telefoneEmergencia: cleanPhoneNumber(fichaContext?.ficha.telefoneEmergencia || ''),
    nomeContatoEmergencia: fichaContext?.ficha.nomeContatoEmergencia || '',
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(
      validateEmail(formData.email) &&
      formData.telefone.length > 0 &&
      formData.telefoneEmergencia.length > 0 &&
      formData.nomeContatoEmergencia.length > 0
    );
  }, [formData]);

  if (!fichaContext) {
    return null;
  }

  const formatPhoneNumber = (phoneNumber: string): string => {
    const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);
    const match = cleanedPhoneNumber.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return cleanedPhoneNumber;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'email' || name === 'nomeContatoEmergencia' ? value : cleanPhoneNumber(value),
    });
  };

  const handleSave = () => {
    onSave('informacoesDeContato', formData);
  };

  const handleExit = () => {
    onExit('onExit', formData);
  };

  const validateEmail = (email: string): boolean => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          Informações de contato
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>
      <form className='formComponente'>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            E-mail <span className='asterisk'>*</span>
          </Typography>
          <TextField
            label=""
            size='small'
            required
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!validateEmail(formData.email) && formData.email.length > 0}
            helperText={!validateEmail(formData.email) && formData.email.length > 0 && "Digite um e-mail válido."}
            className='textField'
            type='email'
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Telefone <span className='asterisk'>*</span>
          </Typography>
          <TextField
            required
            size='small'
            name="telefone"
            value={formatPhoneNumber(formData.telefone)}
            onChange={handleChange}
            label=""
            className='textField'
            type='tel'
            inputProps={{ inputMode: 'numeric', maxLength: 18 }}
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Telefone de emergência <span className='asterisk'>*</span>
          </Typography>
          <TextField
            required
            size='small'
            name="telefoneEmergencia"
            value={formatPhoneNumber(formData.telefoneEmergencia)}
            onChange={handleChange}
            label=""
            className='textField'
            type='tel'
            inputProps={{ inputMode: 'numeric', maxLength: 18 }}
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Nome do contato de emergência <span className='asterisk'>*</span>
          </Typography>
          <TextField
            required
            size='small'
            name="nomeContatoEmergencia"
            value={formData.nomeContatoEmergencia}
            onChange={handleChange}
            label=""
            className='textField'
            type='text'
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

      </form>
      <div className='divButtons'>
        <Button className='buttonSaveNext' variant="contained" onClick={handleSave} disabled={!isFormValid || isLoading}>
          Próxima Etapa
        </Button>
        <Button className='buttonSaveExit' variant="contained" onClick={handleExit} disabled={!isFormValid || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default InformacoesDeContato;
