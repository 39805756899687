import { createContext, ReactNode, useState } from "react"

export type MyAlertProps = {
  open: boolean
  message: string
  waitingResponse: boolean
  severity: "success" | "warning" | "info" | "error"
}

type AlertTypes = {
  alert: MyAlertProps
  setAlert: (alert: MyAlertProps) => void
}

type AlertTypesProps = {
  children: ReactNode
}

export const AlertContext = createContext({} as AlertTypes)

const AlertProvider = (props: AlertTypesProps) => {
  const [alert, setAlert] = useState<MyAlertProps>({
    open: false,
    message: "",
    severity: "success",
    waitingResponse: false,
  })

  return (
    <AlertContext.Provider
      value={{
        alert,
        setAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  )
}

export default AlertProvider
