import { makeStyles } from "@mui/styles"

import { Typography, Container } from "@mui/material"
import ConfirmButton from "../../../components/ConfirmButton"
import OutlineButton from "../../../components/OutlineButton"
import Pagination from "../../../components/Pagination"
import { useState } from "react"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useNavigate } from "react-router-dom"
import useDocumentLink from "../../../hooks/useDocumentLink"
//import { formatDateToBrazilianPattern } from "../../../helpers/format"


function WelcomeLink() {
  const useStyles = makeStyles(theme => ({

    container: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh',
    },

    sliderContainer: {
      maxWidth: 350,
      width: '80vw'
    },

    sliderPage: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'center'
    },

    content: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      paddingTop: 122,
      paddingLeft: 36,
      paddingRight: 36,
      paddingBottom: 30,
      maxHeight: 812,
      minHeight: 688,
    },

    image: {
      width: 250,
      height: 166,
      marginBottom: 40
    },

    title: {
      fontSize: 24,
      lineHeight: "32px",
      fontWeight: 700,
      color: '#003da5',
      textAlign: 'center',
      marginBottom: 19
    },

    text: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "24px",
      color: "#4d4d4d",
      textAlign: 'center'
    },

    flex: { flex: 1 },

    options: {
      display: 'flex',
      alignSelf: 'stretch',
      flexDirection: 'row',
      alignItems: 'center'
    },

    spacer: {
      width: 16
    }


  }))

  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [swiper, setSwiper] = useState<any>(null);
  const navigate = useNavigate()
  const { user } = useDocumentLink();


  const WELCOME_DATA = [
    {
      image: '/assets/images/bem_vindo_link_1.png',
      title: 'Boas-vindas ao Grupo Direcional',
      content: <>Olá {user.nome || 'N/A'}, em breve você fará parte de uma das maiores <strong>construtoras do país!</strong><br /><br />Para isso, precisamos que envie a sua <strong>documentação pessoal.</strong></>
    },
    {
      image: '/assets/images/bem_vindo_link_2.png',
      title: 'Sobre o envio de documentos',
      content: <>Você deve incluir os <strong>documentos necessários</strong> para prosseguirmos com a sua <strong>admissão</strong>.<br /><br />Eles poderão ser enviados <strong>digitalizados em PDF</strong> ou em <strong>foto</strong>, que deverão estar legíveis, sem cortes, com boa qualidade e iluminação.</>
    },
    /* {
      image: '/assets/images/bem_vindo_link_3.png',
      title: 'Atenção ao prazo de envio',
      content: <>O <strong>prazo</strong> para <strong>envio da documentação</strong> e preenchimento do formulário é até {formatDateToBrazilianPattern(user.dataExpiracao)}.</>
    }, */
    {
      image: '/assets/images/bem_vindo_link_4.png',
      title: 'Atenção ao e-mail',
      content: <>Iremos <strong>validar</strong> os <strong>documentos enviados</strong> e verificar se estão todos corretos. Caso haja alguma pendência, solicitaremos um novo envio.<br /><br />A <strong>comunicação</strong> será realizada pelo <strong>e-mail</strong> cadastrado, por isso fique de olho nele, incluindo a caixa de SPAM.</>
    }
  ]

  const elements = WELCOME_DATA.map((d, i) => {
    return (
      <SwiperSlide key={d.image}>
        <div className={classes.sliderPage}>
          <img alt='Logo branco' src={d.image} className={classes.image} />
          <Pagination length={WELCOME_DATA.length} position={i} />
          <Typography variant="body2" className={classes.title}>
            {d.title}
          </Typography>
          <Typography variant="body2" className={classes.text}>
            {d.content}
          </Typography>
        </div>
      </SwiperSlide>
    )
  })

  return (
    <Container maxWidth="sm" className={classes.container}>
      <div className={classes.content}>
        <div className={classes.sliderContainer}>
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            onSlideChange={(swiper) => setPage(swiper.realIndex)}
            onSwiper={setSwiper}
          >
            {elements}
          </Swiper>
        </div>
        <div className={classes.flex} />
        {
          page > 0 ?
            <div className={classes.options}>
              <div className={classes.flex}>
                <OutlineButton onClick={() => swiper.slidePrev()}>
                  VOLTAR
                </OutlineButton>
              </div>
              <div className={classes.spacer} />
              <div className={classes.flex}>
                <ConfirmButton onClick={() => {
                  if (page + 1 < WELCOME_DATA.length) swiper.slideNext()
                  else navigate('/link/home')
                  sessionStorage.setItem('currentPageSwiper', "0");
                }}>
                  AVANÇAR
                </ConfirmButton>
              </div>

            </div> :
            <ConfirmButton onClick={() => swiper.slideNext()}>
              AVANÇAR
            </ConfirmButton>
        }
      </div>
    </Container>
  )

}

export default WelcomeLink
