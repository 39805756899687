import { makeStyles } from "@mui/styles"
import MainButton from "./MainButton"
import useAlert from "../hooks/useAlert"
import BackdropLoading from "./BackdropLoading"
import { swalAlert } from "../helpers/swalAlert"
import { Paper, Grid, Typography } from "@mui/material"
import { FiAlertCircle } from "react-icons/fi"
import { useNavigate } from "react-router-dom"
import useVacancies from "../hooks/useVacancies"
import { requestVacanciesBySections } from "../services/getVacancies"
import { DeleteVacancy } from "../services/api"
import { ClearVacancy } from "../services/api"

interface DeleteClearButtonProps {
  open: boolean
  close: () => void
  typeButton: string
}

export default function DeleteClearButton({
  open,
  close,
  typeButton,
}: DeleteClearButtonProps) {

  const navigate = useNavigate()

  const { setVacancies } = useVacancies()

  const { alert, setAlert } = useAlert()

  const useStyles = makeStyles(theme => ({
    modal: {
      right: 0,
      top: "20%",
      left: "50%",
      zIndex: 99,
      width: "80%",
      maxWidth: 600,
      position: "fixed",
      height: open ? 400 : 0,
      maxHeight: 600,
      borderRadius: 32,
      transition: "height 0.1s",
      transform: "translateX(-50%)",
      display: open ? "flex" : "none",
    },
    modalTitle: {
      fontWeight: 700,
      fontSize: 18,
      color: "#B91C1C",
    },
    buttonsContainer2: {
      padding: 16,
      width: "100%",
      maxWidth: 600,
    },
  }))

  const classes = useStyles()

  const handleDeleteVacancy = async () => {
    setAlert({ ...alert, waitingResponse: true })

    let selectedVacancy = JSON.parse(localStorage.getItem("selectedVacancy") as string)

    let response = await DeleteVacancy(selectedVacancy?.nrChamado)

    if (response?.status >= 200 && response?.status < 300) {
      let vacanciesResponse = await requestVacanciesBySections()

      setVacancies(vacanciesResponse)

      setAlert({ ...alert, waitingResponse: false })

      swalAlert(
        "success",
        `A vaga <strong>${selectedVacancy?.nome}</strong> foi excluída.`,
        "OK, ENTENDI"
      )

      setTimeout(() => navigate("/auth/novas-vagas"), 1000)
    } else {
      setAlert({ ...alert, waitingResponse: false })

      swalAlert("error", response, "TENTAR NOVAMENTE")
    }
  }

  const handleClearVacancy = async () => {
    setAlert({ ...alert, waitingResponse: true });

    const selectedVacancyString = localStorage.getItem("selectedVacancy");
    let selectedVacancy = null;

    if (selectedVacancyString) {
      selectedVacancy = JSON.parse(selectedVacancyString);
    }

    if (selectedVacancy) {
      let response = await ClearVacancy(selectedVacancy.nrChamado);

      if (response?.status >= 200 && response?.status < 300) {
        let vacanciesResponse = await requestVacanciesBySections();

        setVacancies(vacanciesResponse);

        setAlert({ ...alert, waitingResponse: false });

        swalAlert(
          "success",
          `Os dados da vaga <strong>${selectedVacancy.nome}</strong> foram apagados.`,
          "OK, ENTENDI"
        );

        setTimeout(() => navigate("/auth/vagas-em-andamento"), 1000);
      } else {
        setAlert({ ...alert, waitingResponse: false });

        swalAlert("error", response, "TENTAR NOVAMENTE");
      }
    } else {
      setAlert({ ...alert, waitingResponse: false });
      swalAlert("error", "Vaga não encontrada", "TENTAR NOVAMENTE");
    }
  };

  return (
    <>
      <Paper elevation={20} className={classes.modal}>
        <Grid container style={{
          padding: 16,
          justifyContent: "center"
        }}>
          <FiAlertCircle
            size={48}
            color='#B91C1C'
            style={{
              marginBottom: 8,
            }}
          />

          <Grid
            container
            item
            xs={12} sm={12} md={12} lg={12} xl={12}
            alignItems='center'
            style={{
              marginTop: 8,
              marginBottom: 8,
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
              style={{
                textAlign: "center",
              }}>
              {typeButton === "deleteVacancy" && (
                <Typography
                  variant='body1'
                  align='center'
                  fontSize={14}
                >
                  A ação não pode ser desfeita depois, ela irá <strong>remover a vaga </strong>.
                </Typography>
              )}

              {typeButton === "clearVacancy" && (
                <Typography
                  variant='body1'
                  align='center'
                  fontSize={14}
                >
                  A ação não pode ser desfeita depois, ela irá <strong>apagar os dados da vaga </strong>.
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            direction='column'
            alignItems='center'
            className={classes.buttonsContainer2}
          >
            {typeButton === "deleteVacancy" && (
              <MainButton
                size='medium'
                fullWidth={true}
                buttonStyle='contained'
                onClick={handleDeleteVacancy}
                style={{
                  marginBottom: 8,
                  maxWidth: 400,
                  textTransform: "uppercase",
                  backgroundColor: "#B91C1C",
                  color: "#FFF",
                  border: "2px solid #B91C1C",
                  borderRadius: 8,
                  padding: 8,
                  fontWeight: 700,
                  fontSize: 16,
                }}
              >
                Deletar vaga
              </MainButton>
            )}

            {typeButton === "clearVacancy" && (
              <MainButton
                size='medium'
                fullWidth={true}
                buttonStyle='contained'
                onClick={handleClearVacancy}
                style={{
                  marginBottom: 8,
                  maxWidth: 400,
                  textTransform: "uppercase",
                  backgroundColor: "#B91C1C",
                  color: "#FFF",
                  border: "2px solid #B91C1C",
                  borderRadius: 8,
                  padding: 8,
                  fontWeight: 700,
                  fontSize: 16,
                }}
              >
                Deletar documentos
              </MainButton>
            )}

            <MainButton
              size='medium'
              onClick={close}
              fullWidth={true}
              buttonStyle='error'
              style={{
                maxWidth: 400,
                textTransform: "uppercase",
                color: "#1D3054",
                border: "2px solid #1D3054",
                backgroundColor: "#FFF",
                borderRadius: 8,
                padding: 8,
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Cancelar
            </MainButton>

          </Grid>
        </Grid>
      </Paper>

      <BackdropLoading open={alert?.waitingResponse} />
    </>
  )
}
