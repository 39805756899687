import { makeStyles } from "@mui/styles"

import { Grid, useMediaQuery } from "@mui/material"


function LogoHeader() {

  const useStyles = makeStyles(theme => ({

    logo: {
      width: 135,
      marginTop: 24,
      marginBottom: 24,
    },

  }))

  const classes = useStyles()
  const smallMobile = useMediaQuery("(max-height:719px)")

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ 
      width: "100%", 
      backgroundColor: "#1d3054", 
      borderRadius: smallMobile ? 0 : "8px 8px 0px 0px",
      marginBottom: 24
    }}>
      <Grid item>
        <img alt='Logo branco' src='/assets/images/logo_grupo_2.png' className={classes.logo} />
      </Grid>
    </Grid>
  )
}

export default LogoHeader
