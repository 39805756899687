import React, { useState, useContext, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import { FormControl, FormControlLabel, RadioGroup, Radio, Button, Typography, Grid } from '@mui/material';
import { Ficha } from '../typesFicha';

interface Props {
  onSave: (componentName: string, data: Partial<Ficha>) => void;
  onExit: (componentName: string, data: Partial<Ficha>) => void;
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

const EstadoCivil: React.FC<Props> = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);
  const [formData, setFormData] = useState({
    estadoCivil: fichaContext?.ficha.estadoCivil || '',
  });

  const [estadoCivilValido, setEstadoCivilValido] = useState(!!formData.estadoCivil);

  useEffect(() => {
    setEstadoCivilValido(!!formData.estadoCivil);
  }, [formData.estadoCivil]);

  if (!fichaContext) {
    return null;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      estadoCivil: value,
    });
    setEstadoCivilValido(!!value);
  };

  const handleSave = () => {
    onSave('estadoCivil', formData);
  };

  const handleExit = () => {
    onExit('onExit', formData);
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          Estado civil
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>
      <form className='formComponente'>
        <Typography variant="h6" className='titleTypography'>
          Estado Civil <span className='asterisk'>*</span>
        </Typography>
        <FormControl className='formControl' component="fieldset" required>
          <RadioGroup name="estadoCivil" value={formData.estadoCivil} onChange={handleChange}>
            <FormControlLabel value='S' control={<Radio />} label="Solteiro" />
            <FormControlLabel value='C' control={<Radio />} label="Casado" />
            <FormControlLabel value='I' control={<Radio />} label="Divorciado" />
            <FormControlLabel value='P' control={<Radio />} label="Separado" />
            <FormControlLabel value='V' control={<Radio />} label="Viúvo" />
            <FormControlLabel value='O' control={<Radio />} label="Outros" />
          </RadioGroup>
        </FormControl>
      </form>
      <div className='divButtons'>
        <Button className='buttonSaveNext' variant="contained" onClick={handleSave} disabled={!estadoCivilValido || isLoading}>
          Próxima Etapa
        </Button>
        <Button className='buttonSaveExit' variant="contained" onClick={handleExit} disabled={!estadoCivilValido || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default EstadoCivil;
